import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { roles } from "../../../../_constants";
import Loading from "../../../global/components/Loading";
import { routerPaths } from "../../../../config/routes/paths";
import AdminSearch from "../../modules/AdminSearch";
import {
  getAdminSearchResultRequest, getCurrentSpaceBalanceRequest, getUserByIdRequest,
} from "../../../../api";
import {
  adminSearchResultSelector,
  setAdminResults, setAdminResultsLoading, whiteLabelNameSelector,
} from "../../../store/reducers/admin";
import { authUserSelector } from "../../../store/reducers/authentication";
import { setHasCampaign } from "../../../store/reducers/campaign";
import { TextField } from "@mui/material";
import CampaignService from "../../../campaign/services/campaign.service";
import { AdminSidebar } from "../AdminSidebar";
import { AdminSearchView } from "../AdminSearchView";
import { AdminDefaultView } from "../AdminDefaultView";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
// import AdminSidebar2 from "../AdminSidebar2";
import { setBalance, setBalanceLoad, setCurrentUser } from "../../../store/reducers/user";

let timeoutId;

interface IProps {
  open: boolean;
  closeAdminPanel: Function;
}

const AdminDrawer = (props: IProps) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const authUser = useSelector(authUserSelector);
  const whiteLabelName = useSelector(whiteLabelNameSelector);
  const adminSearchResult = useSelector(adminSearchResultSelector);
  const {open, closeAdminPanel} = props;

  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getAdminSearchResult = (value: string) => {
    setIsLoading(true);
    dispatch(setAdminResultsLoading(true))
    getAdminSearchResultRequest({ name: value }).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        dispatch(setAdminResults(res?.data.data))
        dispatch(setAdminResultsLoading(false))
        setIsLoading(false);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const setAdminNewSpace = (id: string, callBack ) => {
    getUserByIdRequest(id).then( async (res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        const user = res?.data?.data;
        dispatch(setCurrentUser(user));
        sessionStorage.setItem('spaceID', user.id);

        const countRes = await CampaignService.getSpaceCampaignCountRequest(user.id)
        dispatch(setHasCampaign(!!countRes?.data?.data));

        dispatch(setBalanceLoad(true));
        const balanceRes = await getCurrentSpaceBalanceRequest(user.id);
        dispatch(setBalance(balanceRes?.data?.data));
        dispatch(setBalanceLoad(false));

        callBack && callBack()
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })

  }

  useEffect(() => {
    getAdminSearchResult("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onQueryChange = (e: any) => {

    setQuery(e.target.value);

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getAdminSearchResult(e.target.value);
    }, 300);
  };

  const onAdminChange = (id: string) => () => {
    const callBack = () => {
      history.replace({ pathname: routerPaths.Campaigns });
      closeAdminPanel();
    }
    setAdminNewSpace(id, callBack);
  };

  const onCampaignChange = (campaignId: string, spaceId: string) => () => {
    const callBack = () => {
      closeAdminPanel();
      history.push(`/campaigns/edit/${campaignId}?tab=basicInfo`);
    }
    setAdminNewSpace(spaceId, callBack);
  };

    return open ? (
      <div>
        <AdminSearch open={open} placement="top" onClose={closeAdminPanel}>
          <div className="admin-drawer">
            <div className="admin-drawer-content">
              <div>
                <TextField
                  className={'admin-drawer-search-input'}
                  value={query}
                  onChange={onQueryChange}
                  placeholder={`Enter Keywords to search ${whiteLabelName || "Intent.ai"}`}
                  autoFocus
                />
              </div>
              {isLoading ? (
                <Loading />
              ) : query ? (
                <AdminSearchView
                  campaigns={adminSearchResult.campaigns}
                  accounts={adminSearchResult.accounts}
                  query={query}
                  allResult={adminSearchResult.all}
                  onAdminChange={onAdminChange}
                  onCampaignChange={onCampaignChange}
                />
              ) : (
                <AdminDefaultView
                  campaigns={adminSearchResult.campaigns}
                  accounts={adminSearchResult.accounts}
                  onAdminChange={onAdminChange}
                  onCampaignChange={onCampaignChange}
                />
              )}
            </div>
            { !authUser.roles.includes(roles.WL_MODERATOR) ? (
              // <AdminSidebar
              //   target="_blank"
              //   moderationCount={
              //     adminSearchResult.campaigns_in_moderation_count +
              //     adminSearchResult.registrants_in_moderation_count
              //   }
              // />

              <AdminSidebar
                // className={'admin-navigation'}
                // isActive={'OPEN'}
                // setIsActive={() => undefined}
                target="_blank"
                moderationCount={
                  adminSearchResult.campaigns_in_moderation_count +
                  adminSearchResult.registrants_in_moderation_count
                }
              />
            ) : null}
          </div>
        </AdminSearch>
      </div>
    ) : null;
}

export default AdminDrawer;

import { Box } from "@mui/material";
import { PropsWithChildren, MouseEvent as ReactMouseEvent, useRef } from "react";
import { style } from './style';

type Props = PropsWithChildren<{
  width: number;
  height: number;
  onResize: Function;
  // [[min width, min height], [max width, max height]]
  bounds: [[number, number], [number, number]];
}>;

function Resizeable({ children, width, height, bounds, onResize }: Props) {
  const elemRef = useRef<HTMLDivElement>();
  const windowWrapper = useRef<HTMLDivElement>();

  function handleMouseDown(e: ReactMouseEvent) {
    e.preventDefault();
    if (windowWrapper.current) {
      windowWrapper.current.style.display = 'block';
    }
    const originalPageX = e.pageX;
    const originalPageY = e.pageY;
    const mouseMoveFn = handleMouseMove(
      originalPageX,
      originalPageY,
      elemRef.current?.offsetWidth,
      elemRef.current?.offsetHeight
    );
    window?.addEventListener('mouseup', handleMouseUp);
    window?.requestAnimationFrame(() => {
      window?.addEventListener('mousemove', mouseMoveFn);
    });
    function handleMouseUp() {
      if (windowWrapper.current) {
        windowWrapper.current.style.display = 'none';
      }
      window?.removeEventListener('mousemove', mouseMoveFn);
      window?.removeEventListener('mouseup', handleMouseUp);
    }
  }

  function handleMouseMove(pageX: number, pageY: number, offsetWidth?: number, offsetHeight?: number) {
    return function(e: MouseEvent) {
      if (elemRef.current && offsetWidth != undefined && offsetHeight != undefined) { // eslint-disable-line
        const newWidth = offsetWidth + (e.pageX - pageX);
        const newHeight = offsetHeight + (e.pageY - pageY);
        const [minWidth, minHeight] = bounds[0];
        const [maxWidth, maxHeight] = bounds[1];
        const widthResizeUnlocked = newWidth >= minWidth && newWidth <= maxWidth;
        const heightResizeUnlocked = newHeight >= minHeight && newHeight <= maxHeight;
        if (widthResizeUnlocked && heightResizeUnlocked) {
          onResize({
            width: newWidth,
            height: newHeight,
          });
        } else if (widthResizeUnlocked) {
          onResize({
            width: newWidth,
          });
        } else if (heightResizeUnlocked) {
          onResize({
            height: newHeight,
          })
        }
      }
    }
  }

  return (
    <Box sx={style.resizer(width, height)} ref={elemRef}>
      {children}
      <Box
        sx={style.resizeIcon}
        onMouseDown={handleMouseDown}
      />
      <Box sx={style.wrapperForWindow} ref={windowWrapper}/>
    </Box>
  )
}

Resizeable.displayName = 'Resizable';

export default Resizeable;
import { ReportingAreaChart, SpendingChart } from "../index";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Backdrop, Box } from "@mui/material";
import { ReportService } from "../../services";
import { Control, useWatch } from "react-hook-form";
import { DashboardData, Granularity, ReportDashboardRequestDTO, ReportTotalTab } from "../../types";
import { style } from "./style";
import { useSelector } from "react-redux";
import { fillDashboardInitialData } from "../../utils";
import CompareLineChart from "../CompareLineCart";
import { Loading } from "../../../global";
import { CURRENCYICONS } from "../../../store";
import { currentUserSelector } from "../../../store/reducers/user";

type Props = {
  control: Control<any>;
  tab: ReportTotalTab;
}
let delayId: any, delay: number = 1000;

function ReportChartView({ control, tab, }: Props) {
  const [dashboardData, setDashboardData] = useState<{ data: Array<DashboardData>; isLoading: boolean; }>({
    data: [],
    isLoading: true,
  });

  const [firstMetric, setFirstMetric] = useState('spent');
  const [secondMetric, setSecondMetric] = useState('cpm');
  const [granularityType, setGranularityType] = useState(Granularity.PER_DAYS);

  const currentUser = useSelector(currentUserSelector);

  const formValues = useWatch({
    control,
  });
  const filtersIsLoading  = useWatch({ control, name: 'filtersIsLoading' })

  function getDashboardData(filter: ReportDashboardRequestDTO) {
    if (!filter.from_date || !filter.to_date) {
      setDashboardData({...dashboardData, isLoading: false});
      return;
    }
    setDashboardData({
      isLoading: true,
      data: fillDashboardInitialData(new Date(filter.from_date), new Date(filter.to_date), filter.granularity_type_key),
    });
    let replacedIndex = 0;
    function getDashboardDataByChunks(filters: Array<ReportDashboardRequestDTO>) {
      if (!filters.length) {
        return;
      }
      const currentChunk = [
        filters.shift(),
        filters.shift(),
        filters.shift(),
        filters.shift(),
      ].filter(Boolean);
      Promise.all(ReportService.getReportDashboard(currentChunk))
        .then(responses => {
          const dashboardData: Array<DashboardData> = [];
          responses.forEach(response => {
            if (response.data.data?.length) {
              dashboardData.push(...response.data.data);
            }
          });

          setDashboardData(data => {
            const dashboardCopy = [...data.data];
            dashboardData.forEach(dashboardData => {
              dashboardCopy[replacedIndex] = dashboardData;
              replacedIndex += 1;
            });
            return { data: dashboardCopy, isLoading: false };
          });
          getDashboardDataByChunks(filters);
        });
    }

    const filters = ReportService.separateReportRequestsWeekly(filter) as Array<ReportDashboardRequestDTO>;
    getDashboardDataByChunks(filters);
  }

  const debounceGetDashboardData = useCallback(() =>{
    clearTimeout(delayId);
      delayId = setTimeout(() => {
        return getDashboardData({
          ...ReportService.buildReportLightDTO(formValues) ,
          granularity_type_key: granularityType,
        })},delay)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, granularityType]
  )

  useEffect(() => {
    if (formValues.campaignId) {
      debounceGetDashboardData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, granularityType]);

  function handleGranularityTypeChange (event: SyntheticEvent, newTab: Granularity) {
    setGranularityType(newTab);
  }

  const dateFormat = granularityType === Granularity.PER_DAYS ? 'MM/dd' : 'MM/dd/hh';

  function renderChart(tabName: ReportTotalTab) {
    if (tabName === ReportTotalTab.COMPARE) {
      return <CompareLineChart
        data={dashboardData.data}
        firstMetric={firstMetric}
        secondMetric={secondMetric}
        setSecondMetric={setSecondMetric}
        setFirstMetric={setFirstMetric}
        currency={CURRENCYICONS[currentUser?.currency]}
        dateFormat={dateFormat}
        granularityType={granularityType}
        handleGranularityTypeChange={handleGranularityTypeChange}
      />;
    } else if (tabName === ReportTotalTab.SPENDING) {
      return <SpendingChart data={dashboardData.data} currency={CURRENCYICONS[currentUser?.currency]} dateFormat={dateFormat} />;
    } else if (tabName === ReportTotalTab.IMPRESSIONS) {
      return <ReportingAreaChart data={dashboardData.data} dateFormat={dateFormat} propertyName={ReportTotalTab.IMPRESSIONS} />;
    } else if (tabName === ReportTotalTab.CONVERSIONS) {
      return <ReportingAreaChart data={dashboardData.data} dateFormat={dateFormat} propertyName={ReportTotalTab.CONVERSIONS} />;
    } else if (tabName === ReportTotalTab.CLICKS) {
      return <ReportingAreaChart data={dashboardData.data} dateFormat={dateFormat} propertyName={ReportTotalTab.CLICKS} />;
    } else if (tabName === ReportTotalTab.BIDS) {
      return <ReportingAreaChart data={dashboardData.data} dateFormat={dateFormat} propertyName={ReportTotalTab.BIDS} />;
    }
    return null;
  }

  return (
    <Box sx={style.chart}>
      {dashboardData.isLoading && filtersIsLoading ? (
        <Backdrop open style={{marginLeft:'85px', backgroundColor: 'transparent', zIndex: 1350 }}>
          <Loading />
        </Backdrop>) : (
        renderChart(tab)
      )}
    </Box>
  );
}

ReportChartView.displayName = 'ReportChartView';

export default ReportChartView;
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import StraightIcon from "@mui/icons-material/Straight";

import { usersHeadConfig } from "./config";
import { useTranslation } from "react-i18next";
import {
  getPaymentUsersRequest,
  getUserByIdRequest,
} from "../../../../../../api";
import TableBodySkeleton from "../../../../../global/components/TableBodySkeleton";
import NoReports from "../../../../../report/components/NoReports";
import { NotificationService } from "../../../../../global/services";
import AdminUserSearch from "./AdminUserSearch";
import AdminInvoicesRow from "./AdminInvoicesRow";
import { useHistory, useParams } from "react-router-dom";
import { StatusCode } from "../../../../../global/types";


let initState = true;

const AdminInvoicesList = ({className = ''}: {className? : string}) => {

  const adminUserSearchRef = useRef(null);
  const history = useHistory();
  const { id } = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice.billing' })

  const [sorting, setSorting] = useState(null);
  const [, setUser] = useState<Nullable<any>>(null);
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [rowsPerPage] = useState<number>(10)
  const [page] = useState(0);
  const [, setTotalCount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const noDataToDisplay = paymentHistoryData?.length === 0 && !isLoading;

  const getPaymentHistory = (id: string) => {
    setIsLoading(true);
    const data = {
      status: null,
      page: page + 1,
      size: rowsPerPage,
    }
    getPaymentUsersRequest(data).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        const _users = [];
        res?.data?.data?.data.forEach(item => {
          _users.push({...item, user_id: item.id, user_name: `${item.first_name} ${item.last_name}`})
        })
        setPaymentHistoryData(_users);
        setTotalCount(res?.data?.data?.total_size);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      setIsLoading(false);
      initState = false;
    })
  }

 const onAccountSelect = (account: Nullable<any>) => {
   const dynamicURL = !!account ? `/admin/user/details/${account.space_id}` : '/admin/invoice';
   history.push(dynamicURL)
   // getPaymentHistory(!!account ? account.space_id : "");
   setUser(account);
 }

 const onRowClick = (id: string) => {
   history.push(`/admin/user/details/${id}`)
 }
 const onNameClick = (userId: string) => {
   getUserByIdRequest(userId).then((res) => {
     if(res?.data?.type?.code === StatusCode.OK && !!res?.data?.data) {
       const name = `${res?.data?.data?.first_name} ${res?.data?.data?.last_name}`
       if(adminUserSearchRef && adminUserSearchRef.current) {
         adminUserSearchRef.current.outsideAccountSet({
           space_name: name,
           currency: res?.data?.data?.currency,
           space_id: res?.data?.data?.id
         });
       }
     }else{
       NotificationService.handleError(res?.data?.type?.message);
     }
   });
 }

  useEffect(() => {
    if(!initState){
      getPaymentHistory(id ? id : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    getPaymentHistory(id ? id : "");

    return () => {
      initState = true;
      setPaymentHistoryData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return  <Paper className={className}>
    <TableContainer className={'report-list'}>
      <Box className={'invoice-list-header top-radius'}>
        <Box sx={{width: '326px'}}>
          <AdminUserSearch ref={adminUserSearchRef} onUserSelect={(account) => onAccountSelect(account)}/>
        </Box>

      </Box>
      <Table>
        <TableHead className={'report-list-head'}>
          <TableRow>
            {
              usersHeadConfig.map(({key, orderField}:IReportHeadCell, index) => orderField ? (
                  <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: '16px'}}>
                    <TableSortLabel
                      IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                      active={sorting.field === orderField}
                      direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                      onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                    >
                      {t(`head.${key}`)}
                    </TableSortLabel>

                  </TableCell>) : (
                  <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: index === 0 ? '16px !important' : '8px !important'}}>
                    {t(`head.${key}`)}
                  </TableCell>
                )
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {noDataToDisplay ? null : (
            isLoading ? (
              <TableBodySkeleton rowCount={rowsPerPage} cellCount={4} />
            ) : (
              <>
                {paymentHistoryData && paymentHistoryData.map((row: any, i) => (
                  <AdminInvoicesRow
                    config={usersHeadConfig}
                    row={row}
                    key={row.id}
                    onRowClick={() => onRowClick(row.user_id)}
                    onNameClick={() => onNameClick(row.user_id)}
                    onSendInvoice={() => undefined}/>
                ))}
              </>
            )
          )}
        </TableBody>
      </Table>
      {noDataToDisplay && (
        <NoReports />
      )}
    </TableContainer>
  </Paper>

}

export default AdminInvoicesList;
import { CampaignFormDTO, MenuItem } from "../../types";
import { style } from "./style";
import { Box, Collapse, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { ArrowDown, InfoRoundedIcon } from "../../../global";
import { CAMPAIGN_FORM_DEFAULT_VALUES } from "../../utils";
import warningIcon from "../../../../assets/images/sidebar_warning.png"
import _ from "lodash";
import { useSelector } from "react-redux";
import { logCountSelector, setLogCount } from "../../../store/reducers/campaign";

type Props = {
  menuItem: Omit<MenuItem, 'advanced'>;
  values: CampaignFormDTO;
  tab: string;
  handleExpandedChange?: (expanded: boolean) => void;
  onTabChange: (tab: string) => void;
}

let hasWarning = false;

const checkDisapproved = (d: any) => {
  if(d && (d.dealsPolicyCompliance.status === 'DISAPPROVED' ||
    d.networkPolicyCompliance.status === 'DISAPPROVED' ||
    d.platformPolicyCompliance.status === 'DISAPPROVED')) {
    return true;
  }
  return false;
};

function CampaignFormMenuItem({ menuItem, tab, onTabChange, handleExpandedChange, values}: Props) {
  const changeLogCount = useSelector(logCountSelector);

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.form' });

  useEffect(() => {
    const hasError = (menuItem.children || []).some(({ hasError }) => hasError);
    if (hasError) {
      handleExpandedChange && handleExpandedChange(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem.children]);

  useEffect(() => {
    if((menuItem.children || []).some(({ value }) => value === tab)){
      handleExpandedChange && handleExpandedChange(true);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, menuItem]);

  useEffect(() => {

    if(!hasWarning && values.visuals.banners?.length){
      const problematics = values.visuals.banners?.filter((item: any) => item.creativeServingDecision);
      hasWarning = problematics.some((b: any) => {
        return checkDisapproved(b.creativeServingDecision)
      })
    }
    if(!hasWarning && values.dynamicAdEnabled && values.dynamicAd?.creativeServingDecision){
      hasWarning = checkDisapproved(values.dynamicAd.creativeServingDecision);
    }
    if(!hasWarning && values.nativeAdEnabled && values.nativeAd?.creativeServingDecision){
      hasWarning = checkDisapproved(values.nativeAd.creativeServingDecision);
    }
    if(!hasWarning && values.videoAdEnabled && values.videoAd?.creativeServingDecision){
      hasWarning = checkDisapproved(values.videoAd.creativeServingDecision);
    }

    return () => {
      setLogCount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const renderStateInfo = (menuItem: string) => {
    switch (menuItem) {
      case 'targeting' : {
        return !values.targeting.automaticTargeting ? (<Box sx={style.stateInfo.parent}>ON</Box>) : null;
      }
      case 'owner_advanced_bidding' : {
        return values.traffic_targeting && !!values.traffic_targeting.length ? (<Box sx={style.stateInfo.children}>{values.traffic_targeting.length}</Box>) : null;
      }
      case 'visuals_responsive_ads' : {
        if(values.dynamicAdEnabled) {
          if(values.dynamicAd?.creativeServingDecision) {
            return checkDisapproved(values.dynamicAd.creativeServingDecision) ?
              <Box sx={style.stateInfo.warning}><img src={warningIcon} alt="warning" /></Box> :
              <Box sx={style.stateInfo.children}>ON</Box>;
          }
          return <Box sx={style.stateInfo.children}>ON</Box>;
        }
        return null;
      }
      case 'visuals_banner_ads' : {
        if(values.visuals.banners?.length){
          const problematics = values.visuals.banners?.filter((item: any) => item.creativeServingDecision)
          if(problematics.length){
            return problematics.some((b: any) => {
               return checkDisapproved(b.creativeServingDecision)
             }) ? <Box sx={style.stateInfo.warning}><img src={warningIcon} alt="warning" /></Box> :
              <Box sx={style.stateInfo.children}>{values.visuals.banners?.length}</Box>;
          }
          return <Box sx={style.stateInfo.children}>{values.visuals.banners?.length}</Box>;
        }
        return null;
      }
      case 'visuals_native_ads' : {
        if(values.nativeAdEnabled) {
          if(values.nativeAd?.creativeServingDecision) {
            return checkDisapproved(values.nativeAd.creativeServingDecision) ?
              <Box sx={style.stateInfo.warning}><img src={warningIcon} alt="warning" /></Box> :
              <Box sx={style.stateInfo.children}>ON</Box>;
          }
          return <Box sx={style.stateInfo.children}>ON</Box>;
        }
        return null;
      }
      case 'visuals_video_ads' : {
        if(values.videoAdEnabled) {
          if(values.videoAd?.creativeServingDecision) {
            return checkDisapproved(values.videoAd.creativeServingDecision) ?
              <Box sx={style.stateInfo.warning}><img src={warningIcon} alt="warning" /></Box> :
              <Box sx={style.stateInfo.children}>ON</Box>;
          }
          return <Box sx={style.stateInfo.children}>ON</Box>;
        }
        return null;
      }
      case 'advanced_frequency' : {
        return Object.values(values.scheduling).some(item => item.length > 0) ? (<Box sx={style.stateInfo.children}>ON</Box>) : null;
      }
      case 'advanced_placement' : {
        return !_.isEqual(CAMPAIGN_FORM_DEFAULT_VALUES.placement, values.placement) ? (<Box sx={style.stateInfo.children}>ON</Box>) : null;
      }
      case 'visuals' : {
        return hasWarning ? <Box sx={style.stateInfo.warning}><img src={warningIcon} alt="warning" /></Box> : null
      }
      case 'change_log' : {
        return changeLogCount ? <Box sx={style.stateInfo.parent}>{changeLogCount}</Box> : null
      }
    }
  }


  const hasChildren = Array.isArray(menuItem.children) && menuItem.children.length > 0;

  return (
    <>
      <Box
        sx={[
          style.tabWrapper,
          tab === menuItem.value && style.selectedTab,
          !!menuItem.expanded && hasChildren && style.childSelected,
          hasChildren && style.tabWithChildren
        ]}
        onClick={hasChildren ? () => handleExpandedChange && handleExpandedChange(!menuItem.expanded) :
          () => {
            handleExpandedChange && handleExpandedChange(false)
            onTabChange(menuItem.value)
        }}
      >
        <Typography sx={style.tab}>
          {t(menuItem.label)}
          {menuItem.hasError && (
            <InfoRoundedIcon sx={style.infoRoundedIcon}/>
          )}
        </Typography>
        {hasChildren ? (
          <>
            {hasWarning ? renderStateInfo(menuItem.value) : null}
            <ArrowDown
              viewBox='0 0 48 48'
              sx={style.arrowIcon(!!menuItem.expanded)}
            />
          </>
        ) : renderStateInfo(menuItem.value)}
      </Box>
      {hasChildren && (
        <Collapse in={menuItem.expanded} timeout="auto" unmountOnExit>
          <Box sx={style.collapsed}>
            {(menuItem.children as Array<MenuItem>).map(item => (
              <CampaignFormMenuItem values={values} menuItem={item} tab={tab} onTabChange={onTabChange} key={item.id} />
            ))}
          </Box>
        </Collapse>
      )}
    </>
  )
}

CampaignFormMenuItem.displayName = 'CampaignFormMenuItem';

export default CampaignFormMenuItem;

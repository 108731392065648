import { NotificationService } from "../../global/services";
import localforage from "localforage";
import { getDictionaryByIdRequest } from "../../../api";
import { StatusCode } from "../../global/types";
import { CampaignService } from "../../campaign/services";
import { CategoryApp } from "../../campaign/types";

class DictService {

  static getDictionary(meta: string) {
    return new Promise((resolve, reject) =>{
      localforage.getItem(meta, async (err, value) => {
        err !== null && NotificationService.handleError(err);
        if(value !== null) {
          resolve(value);
        } else {
          const res = await getDictionaryByIdRequest(meta);
          if(res?.data?.type === 'OK') {
            localforage.setItem(meta, res?.data?.data, (err) => {
              if(err !== null) {
                NotificationService.handleError(err);
              }
            })
            resolve(res?.data?.data);
          }
        }
      })
    })
  }

  static getAppGameCategories(meta: string = CategoryApp.APP) {
    return new Promise((resolve, reject) =>{
      localforage.getItem(meta, async (err, value) => {
        err !== null && NotificationService.handleError(err);
        if(value !== null) {
          resolve(value);
        } else {
          const res = await CampaignService.getCategories(meta);
          if(res?.data?.type.code === StatusCode.OK) {
            localforage.setItem(meta, res?.data?.data, (err) => {
              if(err !== null) {
                NotificationService.handleError(err);
              }
            })
            resolve(res?.data?.data);
          }
        }
      })
    })
  }
}

export default DictService;
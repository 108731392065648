import { Gender } from "./targeting";
import { SelectOption } from "../../global/components/IntForm/IntSelect";
import { FunctionComponent } from "react";

export type AllTimePerformanceDTO = {
  isLoading: boolean;
  data: {
    current_period_impressions: number;
    previous_period_impressions: number | null;
    previous_period_impressions_min_report_date: string | null;
    current_period_clicks: number;
    previous_period_clicks: number | null;
    previous_period_clicks_min_report_date: null | string;
    current_period_bids: number;
    previous_period_bids: number | null;
    previous_period_bids_min_report_date: string | null;
    current_period_spent: number;
    previous_period_spent: number | null;
    previous_period_spent_min_report_date: string | null;
    budget_spent: number;
  } | null;
}

export enum CampaignStatus {
  DRAFT = 0,
  IN_MODERATION = 1,
  REJECTED = 2,
  RUNNING = 3,
  RUNNING_DAILY_BUDGET_REACHED = 31,
  WAITING_TO_START = 40,
  PAUSED = 41,
  FINISHED_BUDGET_SPENT = 42,
  FINISHED_OVERDUE = 43,
  PAUSED_DUE_TO_USER_BALANCE_SPENT = 44,
  PAUSED_PENDING_BALANCE_VALIDATION = 45,
  ARCHIVED = 5,
  WAITING_FOR_AD_EXCHANGE = 6,
  ALL = 50,
}

export enum CategoryApp {
  GAME = 'game',
  APP = 'app',
}

export type DynamicAdForm = {
  isManual: boolean;
  titles: Array<{ title: string; key?: string }>;
  descriptions: Array<{ description: string; key?: string; }>;
  callToAction: string;
  companyName: string;
  images: Array<{ url: string; id: string; }>;
  logo: Array<{ url: string; id: string; }>;
  callToActionColor: string;
}

enum CampaignType {
  BANNER = 1,
  FULL_SCREEN = 2,
  CATFISH = 3,
  INTERACTIVE = 4,
  IN_PAGE_VIDEO = 5,
}

export enum CampaignBudgetType {
  LIFETIME = 1,
  DAILY = 2,
  UNLIMITED = 3,
}

export type CampaignListItem = {
  id: string;
  status_key?: CampaignStatus;
  name?: string;
  creative_status: Nullable<string>;
  campaign_start_at?: string;
  campaign_type_key?: CampaignType;
  daily_budget_spent?: number;
  impressions?: number;
  clicks?: number;
  budget_spent?: number;
  campaignType?: string;
  budget_type_key?: CampaignBudgetType;
  budget?: number;
  report_data?: any;
};

export type CampaignReport = Partial<{
  campaign_id: string;
  current_period_bids: number;
  current_period_clicks: number;
  current_period_impressions: number;
  current_period_spent: number;
  today_spent: number;
  clicks: number;
  impressions: number;
  spent: number;
  ctr: number;
}>;

export type CampaignListFilterDTO = {
  status_filter: Array<string>;
  from_date: Date;
  to_date: Date;
  name: string;
  space_id: string;
  page: number;
  pageSize: number;
  order_field?: string;
  order_asc?: boolean;
}

export type CampaignReportsFilterDTO = {
  campaign_ids: string[];
  from_date: string | Date;
  to_date: string | Date;
}

export type CampaignsList = {
  totalCount: number;
  data: Array<CampaignListItem>;
  isLoading: boolean;
}

export type FileDTO = {
  width?: number;
  height?: number;
  duration?: number;
  size?: number;
  fileId: string;
  filename: string;
  url: string;
};

export type CampaignExportFilter = {
  statusFilter: Array<CampaignStatus>;
  fromDate: string;
  toDate: string;
}

export type CampaignFormDTO = {
  campaignId?: string;
  impressionURL: string;
  replicatedFrom?: string;
  userCPX: number;
  country: string;
  dynamicAdEnabled: boolean;
  timezone_offset_seconds: number;
  campaign_category_keys: string[];
  exchange_id: Array<string>;
  status_key: Nullable<number>;
  nativeAdEnabled: boolean;
  videoAdEnabled: boolean;
  has_whitelist: boolean;
  disregard_targeting: boolean;
  videoAd?: {
    video: null | FileDTO;
    companionBanners: FileDTO[];
    creative_name: string;
    creativeServingDecision: Nullable<IDecision>;
  };
  bidding_strategy?: IBiddingStrategyModel;
  isAutoEnhancement: boolean;
  name: string;
  attribution: any[];
  test_devices: string[];
  id?: string;
  productURL: string;
  campaignStartAt: Date | string;
  campaignEndAt: Date | string;
  budget: number | string;
  scheduling: { [key: number]: Array<string> };
  attributionTags: string;
  cpm: number;
  cpx: number;
  cpxManual: boolean;
  audioAdEnabled: boolean;
  audioAds: Array<{
    fileId: string;
    url: string;
    size: number;
    name: string;
    extension: string;
  }>;
  targeting: Partial<{
    automaticTargeting: boolean;
    ageRange: [number, number];
    interests: {
      includeAny: Array<{ value: string }>;
      exclude: Array<{ value: string }>;
      include: Array<{ value: string }>;
    };
    apps: {
      includeAny: Array<{ id: string; name: string }>;
      include: Array<{ id: string; name: string }>;
      exclude: Array<{ id: string; name: string }>;
    };
    segmentAudience: {
      includeAny: Array<{ id: string; name: string }>;
      include: Array<{ id: string; name: string }>;
      exclude: Array<{ id: string; name: string }>;
    };
    gender: Gender;
    similarity: number;
    cities: string[];
    regions: Array<string>;
    connectionTypes: Array<string>;
    customerAttributes: Array<string>;
    targetLanguages: Array<string>;
  }>;
  visuals: Partial<{
    banners: Array<{
      width: number;
      height: number;
      file_id: string;
      url: string;
      key?: any;
      type_key?: any;
      creative_name?: string;
      creative_attributes?: any;
      google_status?: string;
      creativeServingDecision?: any;
    }>;
  }>;
  dynamicAd: Partial<{
    titles: Array<{ key?: number; title: string }>;
    descriptions: Array<{ key?: number; description: string }>;
    creative_name: string;
    callToAction: string;
    companyName: string;
    images: Array<{ id: string; url: string; }>;
    logo: Array<{ id: string; url: string; }>;
    callToActionColor: string;
    creativeServingDecision: Nullable<IDecision>;
  }>;
  frequencyCap: Partial<{
    1: string;
    2: string;
    3: string;
  }>;
  placement: Partial<{
    files: any[],
    min_os_version?: string;
    excluded_app_categories: Array<SelectOption>;
    included_app_categories: Array<SelectOption>;
    excludeAllGames: boolean;
    exclude_non_gambling_publishers: boolean;
    gameCategories: Array<SelectOption>;
    blockedWebsites?: string[];
    included_apps: any[];
    excluded_apps: any[];
    preferredWebsites?: string[];
    deviceType: number | string;
    environment: string;
    languages: Array<string>;
  }>;
  tab: string;
  t: string | number;
  margin: Nullable<number>;
  intentVerified: boolean;
  telcoModel: string;
  deal_link: string;
  traffic_targeting: any[];
  noTelcoModel: string;
  dummy_models: string;
  intentTagDate: Date | null;
  nativeAd: {
    images: Array<{
      file_id: string;
      url: string;
      width: number;
      height: number;
      file_name: string;
    }>;
    video: FileDTO | null;
    headline: string;
    body: string;
    cta: string;
    advertiser: string;
    logo: {
      file_id: string;
      url: string;
      width: number;
      height: number;
      file_name: string;
    } | null;
    starRating: number;
    price: string;
    creative_name: string;
    creativeServingDecision: Nullable<IDecision>;
  }
}


export type CampaignObjectiveForm = {
  bidding_strategy: {
    click_url?: string;
    goal?: string;
    type: string;
    app_promotion?: {
      bundle_id: string;
      mmp: string;
      app_platform_type: string;
      tracking_url?: string;
    };
    website_promotion?: {
      conversion_url: string;
      custom_action_name: string;
    };
  }
};

export enum CampaignModel {
  TELCO = 'telco',
  NON_TELCO = 'non_telco',
}

export type MenuItem = {
  id: number;
  value: string;
  label: string;
  children: Array<Omit<MenuItem, 'advanced'>>;
  hasError: boolean;
  expanded?: boolean;
  Component: FunctionComponent<any>;
}

export enum CampaignMenu {
  OWNER = 'owner',
  OWNER_MODERATION = 'owner_moderation',
  OWNER_ADVANCED_BIDDING = 'owner_advanced_bidding',
  BASIC_INFO = 'basicInfo',
  TARGETING = 'targeting',
  TRACKS = 'tracks',
  VISUALS_RESPONSIVE_ADS = 'visuals_responsive_ads',
  VISUALS_BANNER_ADS = 'visuals_banner_ads',
  VISUALS_NATIVE_ADS = 'visuals_native_ads',
  VISUALS = 'visuals',
  ADVANCED = 'advanced',
  ADVANCED_PLACEMENT = 'advanced_placement',
  ADVANCED_ATTRIBUTION = 'advanced_attribution',
  ADVANCED_FREQUENCY = 'advanced_frequency',
  ADVANCED_GEOGRAPHY = 'advanced_geography',
  VISUALS_VIDEO_ADS = 'visuals_video_ads',
  CHANGE_LOG = 'change_log',
}
import { Theme } from "@mui/material";

export const style = {
  header: (theme: Theme) => ({
    backgroundColor: '#cdd5de',
    boxShadow: 'inset 0 -1px 0 0 #cdd5de',
    padding: theme.spacing(3, 4),
    gap: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    position: 'fixed',
    width: 'calc(100% - 88px)',
    zIndex: 9,
  }),
  select: {
    backgroundColor: '#fff',
    height: 40,
    width: 'calc(16% - 12px)',
    minWidth: 120,

    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  input: {
    width: 'calc(16% - 12px)',
    minWidth: 120,
  },
  backButton: {
    borderRadius: '24px',
    width: '80%',
    minWidth: 125,
    float: 'right',
  },
  backButtonWrap: {
    width: 'calc(16% - 30px)',
    minWidth: 130,
    // position: 'relative',
  },
  dateRange: {
    // width: 'calc(17% - 12px)',
    minWidth: 230,
    position: 'relative',
  },
  round: (color: string) => ({
    height: '16px',
    width: '16px',
    border: '2px solid #dfe7f0',
    borderRadius: '100%',
    backgroundColor: color,
    margin: '4px 8px 0 0'
  }),
}
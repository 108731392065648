import { useTranslation } from "react-i18next";
import { PersonalInfoForm, ProfileLegalForm, VoiceCPMMultiplierForm } from "../index";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserService } from "../../../user/services";
import { StatusCode } from "../../../global/types";
import { Box } from "@mui/material";
import { style } from './style';
import { IUser } from "../../../../types";
import { authUserSelector } from "../../../store/reducers/authentication";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";
import { NotificationService } from "../../../global/services";
import PaymentForm from "../PaymentForm";
import { roles } from "../../../../_constants";
import { currentUserSelector } from "../../../store/reducers/user";

function Settings() {
  const { t } = useTranslation();
  const [user, setUser] = useState<IUser | null>(null);

  const currentUser = useSelector(currentUserSelector);
  const authUser = useSelector(authUserSelector);


  function getUser(id: string) {
    UserService.getUserById(id)
      .then(response => {
        if (response?.data?.type?.code === StatusCode.OK) {
          setUser(response?.data?.data);
        } else {
          NotificationService.handleError(response?.data?.type?.message);
        }
      })
  }

  useEffect(() => {
    if (currentUser?.id) {
      getUser(currentUser?.id);
    }
  }, [currentUser?.id]);

  return (
    <AuthTitleWrapper
      iconName='profile'
      title={t(`navigation.profile.user`)}
    >
      <Box sx={style.settings}>
        {user && (
          <>
            {authUser.roles.includes(roles.WL_OWNER) ? <PaymentForm user={user} /> : null}
            <VoiceCPMMultiplierForm user={user} />
            <PersonalInfoForm user={user} />
            <ProfileLegalForm user={user} />
          </>
        )}
      </Box>
    </AuthTitleWrapper>
  );
}

Settings.displayName = 'Settings';

export default Settings;
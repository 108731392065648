/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/1/2023
 * All Rights Reserved.
 */
import { Box, Button, CircularProgress, Divider, TextField, Tooltip, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { AddSquareIcon, CrossIcon, getImageDetails, IntFieldController, IntSwitch, TickIcon } from "../../../global";
import { useFormContext, useWatch } from "react-hook-form";
import { UploadService } from "../../../upload/services";
import { StatusCode } from "../../../global/types";
import VideoAdPreview from "../VideoAdPreview";
import { NotificationService } from "../../../global/services";
import { BannerDTO } from "../../types";
import { creativeNameRunTimeValidator } from "../../utils";
import ApprobationStatus from "../ApprobationStatus";

type Props = {
  footer: ReactNode;
}

// const width = 300, height = 250;

function VideoAds({ footer }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.video' });

  const { control, setValue,formState } = useFormContext();

  // const [cBannerWidth, setCBannerWidth] = useState('');
  // const [cBannerHeight, setCBannerHeight] = useState('');
  const [nameError, setNameError] = useState('');
  const [loadingState, setLoadingState] = useState({
    video: false,
    thumbnailImage: false,
    logo: false,
    image: false,
  });

  const [video, headline, cta, videoAdEnabled, companionBanners, creative_name, creativeServingDecision] = useWatch({
    name: ['videoAd.video', 'videoAd.headline', 'videoAd.cta', 'videoAdEnabled', 'videoAd.companionBanners', 'videoAd.creative_name', 'videoAd.creativeServingDecision'],
    control,
  });

  function onBannerRemove(id: string) {
    setValue(
      'videoAd.companionBanners',
      companionBanners.filter((banner: BannerDTO) => banner.file_id !== id)
    );
  }

  function onVideoRemove() {
    setValue('videoAd.video', null);
  }

  const v_adError = formState.errors?.videoAd as any;
  const hasError = !!v_adError?.creative_name;

  // const currentImage = companionBanners.find((banner: BannerDTO) => banner.width === +cBannerWidth && banner.height === +cBannerHeight);

  function loadVideoMetadata(file: File) {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    video.load();

    async function loadDetails() {
      URL.revokeObjectURL(url);
      const response = await UploadService.uploadVideoAdResource(file);
      if (response?.data.type?.code == StatusCode.OK) { // eslint-disable-line
        const {data: { data }} = response;
        setValue('videoAd.video', {
          url: data.file_url,
          fileId: data.file_id,
          filename: file.name,
          size: file.size,
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight,
        });
      } else {
        // NotificationService.handleError(t('somethingWentWrong'));
        NotificationService.handleError(response?.data.type?.message);
      }
      setLoadingState(state => ({
        ...state,
        video: false,
      }));
      video.removeEventListener('loadedmetadata', loadDetails);
    }

    video?.addEventListener('loadedmetadata', loadDetails);
  }

  async function handleFileUpload(e: ChangeEvent<HTMLInputElement>, type: string) {
    const file = e.target.files?.[0];
    if (file) {
      if (type === 'VIDEO') {
        setLoadingState(state => ({
          ...state,
          video: true,
        }));
        loadVideoMetadata(file);
      } else {
        setLoadingState(state => ({
          ...state,
          image: true,
        }));
        const dimensions = await getImageDetails(file);
        // setCBannerHeight(dimensions.height.toString());
        // setCBannerWidth(dimensions.width.toString());
        // if (dimensions.width === width && dimensions.height === height) {
          const res = await UploadService.uploadNativeFile(file);
          if (res?.data?.data) {
            setValue(
              'videoAd.companionBanners',
              [...companionBanners, { width: dimensions.width , height: dimensions.height, file_id: res?.data?.data.file_id, url: res?.data?.data.file_url }]
            );
            setLoadingState(state => ({
              ...state,
              image: false,
            }));
          }
        // } else {
        //   NotificationService.handleError(t('bannerSizeErr'));
        // }
      }
      e.target.value = '';
    }
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      'videoAd.creative_name',
      creativeNameRunTimeValidator(e.target.value, setNameError)
    );
  }

  useEffect(() => {
    if(hasError)
      setNameError('Creative Name is a required field');
  }, [hasError]);


  return (
    <Box sx={style.videoAds}>
      <Box sx={style.header}>
        <Typography sx={style.title}>
          {t('title')}
        </Typography>
        <Tooltip title={t(videoAdEnabled ? 'disable' : 'enable')}>
          <Box>
            <IntFieldController
              control={control}
              name='videoAdEnabled'
              Component={IntSwitch}
            />
          </Box>
        </Tooltip>
      </Box>
      <Divider />
      <ApprobationStatus decision={creativeServingDecision} />
      <Box sx={style.content}>
        <Box sx={style.fields}>
          <Box sx={style.field}>
            <Typography sx={style.label}>CREATIVE NAME *</Typography>
            <TextField
              value={creative_name || ""}
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => handleNameChange(e as ChangeEvent<HTMLInputElement>)}
              placeholder={'Enter banner name'}
              sx={{...style.names(nameError), }}
            />
            {!!nameError ? <Typography sx={style.errorText}>{nameError}</Typography> : null}
          </Box>
          <Box>

            <Typography sx={style.label}>{t('video')} *</Typography>
            <Typography sx={style.helper}>{t('videoSizeDetails')}</Typography>
            <Box sx={style.videoField}>
            <Button sx={style.button} startIcon={(
              // <Box sx={style.addIconWrapper}>
                loadingState.video ? (
                  <Box sx={style.addIconWrapper}>
                    <CircularProgress size='small' sx={style.loadingIcon}/>
                  </Box>
                ) : video ? (
                  <Box sx={style.addIconWrapper}>
                    <TickIcon sx={style.tickIcon} />
                    <CrossIcon style={style.crossIcon} onClick={onVideoRemove} />
                  </Box>
                ) : (
                  <Box sx={style.addIconWrapper}>
                    <AddSquareIcon sx={style.addIcon}/>
                  </Box>
                )
              // </Box>
            )}>
              {t(video ? 'delete' : 'addVideo')}
              {!video && <Box
                component='input'
                accept='video/*'
                type='file'
                sx={style.hiddenInput}
                onChange={(e) => handleFileUpload(e, 'VIDEO')}
              />}
            </Button>
            </Box>
          </Box>
          <Box>
            <Typography sx={style.label}>{t('companionBanner')} </Typography>
            {/*<Typography sx={style.helper}>{t('companionBannerSizeDetails')}</Typography>*/}
            <Typography sx={style.helper}></Typography>
            <Button sx={style.button} startIcon={(
              <Box sx={style.addIconWrapper}>
                {loadingState.image ? (
                  <CircularProgress size='small' sx={style.loadingIcon}/>
                ) :
                //   currentImage ? (
                //   <TickIcon sx={style.tickIcon} />
                // ) :
                    (
                  <AddSquareIcon sx={style.addIcon}/>
                )}
              </Box>
            )}>
              {/*{t(currentImage ? 'change' : 'addImage')}*/}
              {t('addImage')}
              <Box
                component='input'
                accept='.jpg, .png, .jpeg'
                type='file'
                sx={style.hiddenInput}
                onChange={(e) => handleFileUpload(e, 'IMAGE')}
              />
            </Button>
          </Box>
        </Box>
        <Box>
          <VideoAdPreview
            onBannerRemove={(id) => onBannerRemove(id)}
            companionBanners={companionBanners}
            videoURL={video?.url}
            headline={headline} cta={cta}
          />
        </Box>
      </Box>
      {footer}
    </Box>
  )
}

VideoAds.displayName = 'VideoAds';

export default VideoAds;
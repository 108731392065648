import { createSlice } from '@reduxjs/toolkit';
import { IProfileImage, ISpace } from "../../../types";

interface IUserSlice {
  id: string;
  email: string;
  verified?: boolean;
  invoice_due_day: any;
  credit_limit: number;
  first_name: string;
  last_name: string;
  currency: string;
  phone: string;
  locale: string;
  balance: Nullable<string>;
  balanceLoad: boolean,
  current_space_id: string;
  completion_status: boolean;
  profile_image: IProfileImage;
  spaces: { [index: string]: ISpace[] };
  role_key: number;
  roles: string[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  language_key: number | string;
  allow_payment: boolean;
  settings: { [index: string]: any };
  whitelabel_id: string;
  legal_entity_name?: string;
  tin_number?: string;
  moderator_comment?: string;
  tos_status_type_key?: null | number;
  status_key: number;
  rewarded_ads_cpm_rate: number;
}


export const userSlice = createSlice({
  name: 'userStore',
  initialState: {
    id: "",
    email: "",
    invoice_due_day: null,
    credit_limit: null,
    first_name: "",
    last_name: "",
    currency: "",
    phone: "",
    locale: "",
    profile_image: null,
    role_key: null,
    roles: [],
    created_at: null,
    updated_at: null,
    deleted_at: null,
    language_key: null,
    settings: {},
    balance: null,
    balanceLoad: true,
    whitelabel_id: "",
    legal_entity_name: "",
    tin_number: "",
    moderator_comment: "",
    tos_status_type_key: null,
    status_key: null,
    rewarded_ads_cpm_rate: null,
} as IUserSlice,

  reducers: {
    setCurrentUser: (state, action) => ({
      ...state, ...action.payload
    }),
    setBalance: (state, action) => ({
      ...state, balance: action.payload
    }),
    setBalanceLoad: (state, action) => ({
      ...state, balanceLoad: action.payload
    }),
  },
});

export const {
  setCurrentUser,
  setBalance,
  setBalanceLoad } = userSlice.actions;

export const currentUserSelector = (state: any) => state.userStore;

export default userSlice.reducer;

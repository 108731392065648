import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { billingMessages } from "../../messages";
import "./style.scss";
import { USER_TYPES } from "../../_constants";
import clsx from "clsx";
import { balanceParser } from "../index";
import { termsOfServiceLinkSelector } from "../store/reducers/admin";
import Loading from "../global/components/Loading";
import { AddPaymentMethods } from "./components/AddPaymentMethods";
import { Flex } from "../global/components/Flex";
import { ContentWrapper } from "../global/components/ContentWrapper";
import InvoicesList from "./components/invoicesList";
import { AuthTitleWrapper } from "../global/components/AuthTitleWrapper";
import { currentUserSelector } from "../store/reducers/user";


const Billing = (props: any) => {
  const {
    intl: { formatMessage },
  } = props;

  const currentUser = useSelector(currentUserSelector);

  const termsOfServiceLink = useSelector( termsOfServiceLinkSelector);

  const noFunds = currentUser?.balance < 1000;
  const lowBalance = currentUser?.balance < 500;

  return (
    <AuthTitleWrapper
      iconName="billing"
      title={formatMessage(billingMessages.title)}
    >
      {currentUser?.balanceLoad ? <Loading /> : <>
        <div className="billing-screen ">
          <div className="payment-methods-and-funds top-radius">
            <ContentWrapper className={'top-radius'}>
              <Flex
                justifyContent="space-between"
                customClassName="aviable-funds-info"
                alignItems="center"
              >
                <div>
                  <p className="available-funds">
                    <FormattedMessage
                      id="Payments.availableFunds"
                      defaultMessage="Available funds"
                    />
                  </p>
                  <p
                    className={clsx("funds", {
                      "no-funds": noFunds,
                      "low-balance": lowBalance,
                    })}
                  >
                    {balanceParser(currentUser?.balance, currentUser?.currency)}
                  </p>
                </div>
              </Flex>
              <div className="payment-methods">
                <AddPaymentMethods />
              </div>
            {currentUser.user_type_key === USER_TYPES.company ? (
              <p className="int-info-text">
                <FormattedMessage
                  id="Payments.corporateCard"
                  defaultMessage="We kindly ask you to attach a corporate card in order to receive official transaction statements or proceed with other actions in compliance with our"
                />{" "}
                <a
                  href={termsOfServiceLink}
                  target="_blank"
                  className="link-hover"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="Auth.termOfService"
                    defaultMessage="Terms of Service"
                  />
                  {"."}
                </a>
              </p>
              ): null
            }
          </ContentWrapper>
        </div>
          {!currentUser?.balanceLoad ? <InvoicesList className={'top-radius'} /> : null }
        </div>
        {/*{isOpen && (*/}
        {/*  <Modal*/}
        {/*    modalData={modalData}*/}
        {/*    modalType={modalType}*/}
        {/*    toggle={toggle}*/}
        {/*    isOpen={isOpen}*/}
        {/*    openModal={openModal}*/}
        {/*    closeModal={closeModal}*/}
        {/*    modalTypes={modalTypes}*/}
        {/*  />*/}
        {/*)}*/}
      </>}
    </AuthTitleWrapper>
  );
}

export default injectIntl(Billing)

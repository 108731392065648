import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { authUserSelector } from "../../../store/reducers/authentication";
import { roleChecker } from "../../utils";

type RenderProps = (active: boolean) => React.ReactElement;

interface IProps {
  path: string;
  icon: RenderProps;
  label: string;
  showRoles: string[];
  disabled?: boolean;
}

export const SidebarItem = ({
  path,
  icon,
  label,
  showRoles,
  disabled,
}: IProps) => {

  const authUser = useSelector(authUserSelector);

  if (!roleChecker(authUser?.roles, showRoles)) return null;
  return (
    <div>
      <Link
        to={path}
        onClick={(event) => (disabled ? event.preventDefault() : undefined)}
        className={clsx("side-bar-item-link", {
          disabled,
        })}
      >
        <div className="dsp-sidebar-item dsp-sidebar-link">
          <div className="dsp-sidebar-icon-wrapper">
            {icon(window.location.pathname.includes(path))}
          </div>
          <span
            className={clsx("dsp-sidebar-labels", {
              "dsp-sidebar-label": window.location.pathname.includes(path),
            })}
          >
            {label}
          </span>
        </div>
      </Link>
    </div>
  );
};

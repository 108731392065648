import { Box, Button, Typography } from "@mui/material";
import { style } from './style';
import './style.scss';
import { differenceInDays, getPreviousWeek, IntFieldController, IntSelect } from "../../../global";
import { Control, SetFieldValue, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { CampaignService } from "../../../campaign/services";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { NotificationService } from "../../../global/services";
import IntDateRangePicker from "../../../global/components/IntForm/IntDateRangePicker";
import { CAMPAIGN_STATUS_INDICATIONS } from "../../../campaign/utils";
import { CampaignStatus } from "../../../campaign/types";
import moment from "moment";
import _ from "lodash";
import { BANNER_SIZE_DICT_LIST } from "../../utils";
import { StatusCode } from "../../../global/types";
import DictService from "../../../dict/services/dict.service";
import { currentUserSelector } from "../../../store/reducers/user";

type Props = {
  control: Control<any>;
  setValue: SetFieldValue<any>;
}

function ReportHeaderFilter({ control, setValue }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'reporting.filter' })

  const [campaignList, setCampaignList] = useState<any[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [deviceTypes, setDeviceTypes] = useState<any[]>([]);
  const [campaignObj, setCampaignObj] = useState<any>({});
  const [diffInDays, setDiffInDays] = useState<Nullable<number>>(null);

  const campaignId = useWatch({ control, name: 'campaignId' })

  const currentUser = useSelector(currentUserSelector);

  const history = useHistory();
  const { search } = useLocation();
  const campaignIdFromParams = new URLSearchParams(search).get('campaignId');

  const CampaignNameIndicator = (campaign: any) => {
    if(campaign.status_key === CampaignStatus.RUNNING) {
      return (
        <Box sx={{display: 'flex'}}>
          <Box className={'campaign-status-indicator'} sx={style.round(CAMPAIGN_STATUS_INDICATIONS[campaign.status_key || CampaignStatus.DRAFT])} />
          <Typography>{campaign.name}</Typography>
          <Typography className={'campaign-status-text'}>{t('live')}</Typography>
        </Box>
      )
    }
    return <Typography className={'campaign-name'}>{campaign.name}</Typography>
  }

    function getCampaignReportList(search: string | undefined) {
    if (currentUser?.id) {
      CampaignService.getCampaignReportList(currentUser?.id)
        .then(response => {
          if(response?.data?.data && !_.isEmpty(response?.data?.data)){
            setCampaignObj(response.data.data);
            const list = [];
            for (let key in response.data.data) {
             if(key !== campaignIdFromParams){
               list.push({ value: key, label: (CampaignNameIndicator(response.data.data[key])), campaignStartDate: response.data.data[key].campaign_start_at });
             }

            }
            setCampaignList(list);
            const _id = campaignIdFromParams || list[0]?.value
            setValue('campaignId', _id);
            const searchParams = new URLSearchParams(search);
            searchParams.set("campaignId", _id);
            history.push({
              search: searchParams.toString()
            })
          } else {
            NotificationService.handleError(t('campaignNotFound'))
            setValue('filtersIsLoading', false)
          }
        });
    }
  }

  const getDictionaryValues = async () => {
    DictService.getDictionary("report_device_types").then((dict: any[]) => {
      setDeviceTypes(dict.map((item: any) => ({ label: item.title, value: item.key })))
    })
  }

  useEffect(() => {
    getCampaignReportList(search);
    // eslint-disable-next-line
  }, [currentUser?.id]);
  //
  useEffect(() => {
    const campaignStartDate = [...[selectedCampaign], ...campaignList].find(({ value }) => value === campaignId)?.campaignStartDate; // eslint-disable-line
    campaignStartDate ? setDiffInDays(differenceInDays(new Date(campaignStartDate), new Date())) :
      setDiffInDays(differenceInDays(getPreviousWeek(), new Date()));

  }, [campaignList, campaignId, selectedCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(campaignIdFromParams){
      CampaignService.getCampaignById(campaignIdFromParams).then(res => {
        if(res?.data?.type?.code === StatusCode.OK) {
          const campaignStartDate = res?.data?.data?.campaign_start_at;
          campaignStartDate ? setDiffInDays(differenceInDays(new Date(campaignStartDate), new Date())) :
            setDiffInDays(differenceInDays(getPreviousWeek(), new Date()));
          setSelectedCampaign({ value: campaignIdFromParams, label: (CampaignNameIndicator(res?.data?.data)), campaignStartDate: campaignStartDate });
        }
      })
    }
    getDictionaryValues();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomPresetRange = () => {
    const start = -moment(new Date()).subtract(2,'months').endOf('month').diff(new Date(),'days');
    const end = -moment(new Date()).subtract(1,'months').endOf('month').diff(new Date(),'days') +1;
    return [start, end];
  }


  return (
    <Box sx={style.header}>
      <IntFieldController
        control={control}
        name='campaignId'
        onChange={(e) => {
          const _target: any = e.target;
          setValue('campaignId', _target.value)
          const searchParams = new URLSearchParams(search);
          searchParams.set("campaignId", _target.value);
          history.push({
            search: searchParams.toString()
          })
        }}
        Component={IntSelect}
        options={[...[selectedCampaign], ...campaignList]}
        title={campaignObj ? campaignObj[campaignId]?.name : ""}
        sx={style.select}
      />
      <IntFieldController
        control={control}
        name='publisher'
        placeholder={t<string>('InputTrafficSource')}
        sx={style.input}
      />
      <IntFieldController
        control={control}
        name='visualSizeKey'
        Component={IntSelect}
        options={BANNER_SIZE_DICT_LIST}
        sx={style.select}
      />
      <IntFieldController
        control={control}
        name='deviceTypeKey'
        Component={IntSelect}
        options={deviceTypes}
        sx={style.select}
      />
      <Box sx={style.dateRange}>
        {diffInDays !== null ? (
          <>
            <IntFieldController
              key={diffInDays}
              control={control}
              name='date'
              Component={IntDateRangePicker}
              includeDefaultPresets={false}
              presets={[
                { id: 7, label: t('allTime'), value: diffInDays, selected: false },
                { id: 1, label: 'Today', value: 0, selected: false },
                { id: 5, label: t('thisMonth'), value: +moment().date() - 1, selected: false },
                { id: 6, label: t('pastMonth'), value: getCustomPresetRange(), selected: false },
                { id: 2, label: 'Last 7 days', value: 7, selected: true },
                { id: 3, label: 'Last 30 days', value: 30, selected: false },
                { id: 4, label: 'Last 365 days', value: 365, selected: false },
                ]}
            />
            <Typography sx={{ fontSize: '.9rem', position: 'absolute', top: '10px', right: '7px'}}>{'(UTC)'}</Typography>
          </>):
          null
        }
      </Box>
      <Box sx={style.backButtonWrap}>
      <Button onClick={() => {
        history.push(`/campaigns/edit/${campaignId}`)
      }} variant={'contained'} sx={style.backButton}>{'Vew Campaign'}</Button>
      </Box>
    </Box>
  );
}

ReportHeaderFilter.displayName = 'ReportHeaderFilter';

export default ReportHeaderFilter;
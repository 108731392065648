import { roles } from "../../../../_constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PersonalInfoForm, ProfileLegalForm } from "../index";
import ProfileSecurityForm from "../ProfileSecurityForm";
import { Box } from '@mui/material';
import { style } from './style';
import ProfileOtherForm from "../ProfileOtherForm";
import { UserType } from "../../types";
import ProfileNotifications from "../ProfileNotifications";
import { authUserSelector } from "../../../store/reducers/authentication";
import { AuthTitleWrapper } from "../../../global/components/AuthTitleWrapper";
import { currentUserSelector } from "../../../store/reducers/user";

function Profile() {
  const { t } = useTranslation();

  const authUser = useSelector(authUserSelector);
  const currentUser = useSelector(currentUserSelector);

  const isLegalPerson = !authUser.roles.includes(roles.WHITELABEL_ACCOUNTANT)// eslint-disable-line
  && authUser.user_type_key == UserType.LEGAL; // eslint-disable-line

  return (
    <AuthTitleWrapper
      title={t(`navigation.profile.${authUser.roles.includes(roles.WL_OWNER) ? 'whiteLabel' : 'user'}`)} // eslint-disable-line
      iconName="settings"
      isProfilePage
    >
      <Box sx={style.formWrapper}>
        <PersonalInfoForm user={authUser} />
      </Box>
      {isLegalPerson && (
        <Box sx={style.formWrapper}>
          <ProfileLegalForm user={authUser}/>
        </Box>
      )}
      <Box sx={style.formWrapper}>
        <ProfileSecurityForm />
      </Box>
      {isLegalPerson && (
        <Box sx={style.formWrapper}>
          <ProfileNotifications userAccount={currentUser}/>
        </Box>
      )}
      <ProfileOtherForm user={authUser}/>
    </AuthTitleWrapper>
  );
}

Profile.displayName = 'Profile';

export default Profile;

import React from "react";
import {
  IAdminSearchResultCampaign,
  IAdminSearchResultAccount,
} from "../../../../types";
import "./style.scss";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { authUserSelector } from "../../../store/reducers/authentication";
import { AdminAccount } from "../AdminAccount";
import { AdminCampaign } from "../AdminCampaign";

interface IProps {
  campaigns: IAdminSearchResultCampaign[];
  accounts: IAdminSearchResultAccount[];
  onAdminChange: Function;
  onCampaignChange: Function;
}

export const AdminDefaultView = ({
  campaigns,
  accounts,
  onAdminChange,
  onCampaignChange,
}: IProps) => {

  const authUser = useSelector(authUserSelector);

  const myAccount = accounts.find(({ user_id }) => user_id === authUser.id);

  return (
    <div className="admin-default-view">
      <div className="admin-default-view-container accounts">
        <div className="admin-default-view-title">
          <FormattedMessage
            id="Admin.adminAccounts"
            defaultMessage="Accounts"
          />
        </div>
        <ul className="admin-default-view-accounts admin-search-list">
          {myAccount && (
            <AdminAccount
              account={myAccount}
              key={myAccount.space_id}
              onAdminChange={onAdminChange}
              isAdmin
            />
          )}

          {accounts.length !== 0 ? (
            accounts.map(account => (
              <AdminAccount
                account={account}
                key={account.space_id}
                onAdminChange={onAdminChange}
              />
            ))
          ) : (
            <div className="admin-default-view-not-found">
              <FormattedMessage
                id="Admin.adminAccountsNotFound1"
                defaultMessage="We are sorry!"
              />
              <FormattedMessage
                id="Admin.adminAccountsNotFound2"
                defaultMessage="There are no Accounts registered on your Platform."
              />
              <FormattedMessage
                id="Admin.adminAccountsNotFound3"
                defaultMessage="You can start promoting your platform and find Advertisers interested in creating campaigns."
              />
            </div>
          )}
        </ul>
      </div>
      <div className="admin-default-view-container campaigns">
        <div className="admin-default-view-title">
          <FormattedMessage
            id="Admin.adminCampaigns"
            defaultMessage="Campaigns"
          />
        </div>
        <ul className="admin-default-view-campaigns admin-search-list">
          {campaigns.length !== 0 ? (
            campaigns.map((campaign, i) => (
              <AdminCampaign
                campaign={campaign}
                key={campaign.campaign_id + i}
                onCampaignChange={onCampaignChange}
              />
            ))
          ) : (
            <div className="admin-default-view-not-found">
              <FormattedMessage
                id="Admin.adminCampaignsNotFound1"
                defaultMessage="We are sorry!"
              />
              <FormattedMessage
                id="Admin.adminCampaignsNotFound2"
                defaultMessage="There are no campaigns published on your Platform."
              />
              <FormattedMessage
                id="Admin.adminCampaignsNotFound3"
                defaultMessage="You can follow-up with the Accounts registered on your platform and encourage them create campaigns"
              />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdminDefaultView;

import { createSlice } from '@reduxjs/toolkit';
import { ISignUpUser, ISpace, IUser, IUserAccount } from "../../../types";

export interface AuthState {
  user: null | IUser;
  authUser: null | IUser;
  token?: object;
  authToken?: string;
  isAuthenticated: boolean;
  isLoaded: boolean;
  isPersonalChangesLoaded: boolean;
  isSecurityChangesLoaded: boolean;
  isNotificationChangesLoaded: boolean;
  dictionary: { [key: string]: object };
  space: ISpace;
  account: IUserAccount;
  registerUser: ISignUpUser;
  hasCampaign: boolean | null;
}

export const authenticationSlice = createSlice({

  name: "authenticationStore",
  initialState: {
    authUser: null,
    isAuthenticated: false,

    user: null,
    token: {},
    authToken: '',
    isLoaded: false,
    isPersonalChangesLoaded: false,
    isSecurityChangesLoaded: false,
    isNotificationChangesLoaded: false,
    space: {},
    account: {},
    registerUser: {},
    hasCampaign: false,
  } as AuthState,
  reducers: {

    setAuthUser: (state, action): AuthState => ({
      ...state,
      authUser: action.payload,
      isAuthenticated: true,
      isLoaded: true,
    }),



    setPersonalChangesLoaded: (state, action) => ({
      ...state,
      isPersonalChangesLoaded: action.payload,
    }),
    setSecurityChangesLoaded: (state, action) => ({
      ...state,
      isSecurityChangesLoaded: action.payload,
    }),
    setNotificationChangesLoaded: (state, action) => ({
      ...state,
      isNotificationChangesLoaded: action.payload,
    }),
    setSpace: (state, action): AuthState => ({
      ...state,
      space: action.payload,
    }),
    setToken: (state, action: any): AuthState => ({
      ...state,
      token: action.payload,
    }),
    setAuthToken: (state, action: any): AuthState => ({
      ...state,
      authToken: action.payload,
    }),
    setTokenInvalid: (state: AuthState): AuthState => ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
    }),
    setLogout: (state: AuthState): AuthState => {
      return ({
      ...state,
      token: {},
      user: null,
      isLoaded: true,
      isAuthenticated: false,
    })},
    setUpdatedUser: (state: AuthState, action): AuthState => ({
      ...state,
      user: action.payload,
    }),
    setUserAccount: (state: AuthState, action): AuthState => ({
      ...state,
      account: action.payload,
    }),
    setRegisterUser: (state: AuthState, action): AuthState => ({
      ...state,
      registerUser: action.payload,
    }),
    nullifyUserAccount: (state: AuthState): AuthState => ({
      ...state,
      account: {},
    }),
    setSpaceId: (state: AuthState, action): AuthState => ({
      ...state,
      space: { ...state.space, id: action.payload },
    }),
    setSpaceMaxMargin: (state: AuthState, action): AuthState => ({
      ...state,
      space: { ...state.space, maxMargin: action.payload },
    }),
    setHasCampaign: (state: AuthState, action): AuthState => ({
      ...state,
      hasCampaign: action.payload,
    }),
  },
});

export const {

  setAuthUser,
  setLogout,
  setAuthToken,
  setHasCampaign,
  setRegisterUser,
  setSpaceMaxMargin,

} = authenticationSlice.actions;

export const getIsAuth = (state: any) => state.authenticationStore?.isAuthenticated;
export const isUserLoaded = (state: any) => state.authenticationStore?.isLoaded;
export const selectCurrentUserSpaceMaxMargin = (state: any) => state.authenticationStore?.space?.maxMargin;
export const authUserSelector = (state: any) => state.authenticationStore?.authUser;
export const selectWhiteLabelId = (state: any) => state.authenticationStore?.user?.whitelabel_id;

export default authenticationSlice.reducer;

/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/1/2023
 * All Rights Reserved.
 */
import { style } from "../NativeAdPreview/style";
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { VIDEO_AD_PREVIEW_HTML } from "../../utils/banner-html";
import { setVideoAdIframeContent } from "../../utils";
import { TrashIcon } from "../../../global";

type Props = {
  companionBanners: any[];
  videoURL: string;
  imageUrl?: string;
  headline: string;
  cta: string;

  onBannerRemove: (id: string) => void;
}

function VideoAdPreview({ onBannerRemove, companionBanners, imageUrl, videoURL, headline, cta }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.video' });

  function loadIframeContent() {
    const iframe = document.querySelector<HTMLIFrameElement>('.iframe');
    if (iframe) {
      setVideoAdIframeContent(iframe, { video: videoURL, headline, cta });
    }
  }

  useEffect(() => {
    if (videoURL) {
      loadIframeContent();
    }
  }, [headline, cta, videoURL]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={style.adPreview}>
      <Box sx={style.header}>
        <Typography sx={style.adPreviewType}>{t('adPreviewTitle')}</Typography>
      </Box>
      <Box sx={style.previewIframe}>
        {videoURL && ( // eslint-disable-next-line
          <iframe
            srcDoc={VIDEO_AD_PREVIEW_HTML}
            width={'100%'}
            height={268}
            style={{
              alignSelf: 'center',
              boxShadow: '0 6px 24px 0 rgba(0, 0, 0, 0.05)',
            }}
            className='iframe'
            onLoad={loadIframeContent}
          ></iframe>
        )}
      </Box>
      <Box sx={style.previewIframe}>
        { companionBanners.map(image => (
          <Box key={image.file_id} sx={[style.banner(300, 250), style.imageWrapper]}>
            <img style={{
              width:'300px',
              height:'250px',
              boxSizing: 'unset',
              borderRadius: 1,
            }}  src={image.url} alt={'companion banner'}/>
            <Box sx={style.trashIcon} onClick={() => onBannerRemove(image.file_id)}>
              <TrashIcon width={24} height={24} />
            </Box>
        </Box>))
        }
      </Box>
    </Box>
  )
}

export default VideoAdPreview;

import { Theme } from "@mui/material";

export const style = {
  placementInputs: (theme: Theme) => ({
    display: 'flex',
    marginTop: theme.spacing(3),
    gap: '5%',
    flexWrap: 'wrap',
    padding: theme.spacing(3, 2, 4),
  }),
  appCategories: {
    flexBasis: '100%',
  },
  fieldLabel: (theme: Theme) => ({
    fontSize: 14,
    letterSpacing: '.8px',
    lineHeight: '16px',
    color: 'rgba(41,47,65,.5)',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  }),
  excludeAllGames: (theme: Theme) => ({
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
  }),
  excludeAllGamesLabel: {
    fontSize: 14,
    lineHeight: '1.71',
    color: '#505d68',
  },

  whiteListToggle: {
    fontSize: 14,
    lineHeight: '1.71',
    color: '#505d68',
    marginRight: '24px',
  },
  whiteListToggleWrap: {
    display: 'flex',
    marginBottom: '24px',
    marginTop: '24px'
  },
  textArea: {
    height: 'fit-content',
  },
  gameCategories: (theme: Theme) => ({
    flexBasis: '100%',
    marginBottom: theme.spacing(6),
  }),
  placementSelectFields: (theme: Theme) => ({
    flexBasis: '47.5%',
    marginBottom: theme.spacing(6),
  }),
  field: {
    width: '100%',
  },
  inputField: {
    width: '100%',
    marginTop: '16px',
  },
  description: (theme: Theme) => ({
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.2px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginTop: theme.spacing(2),
  }),
  devicesToDisplay: (theme: Theme) => ({
    marginTop: theme.spacing(4),
  }),
  selectField: {
    height: 40,
  },
  title: {
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '-0.2px',
    color: '#292f41',
    marginBottom: 5,
  },
  languageField: (theme: Theme) => ({
    marginTop: theme.spacing(4),
  }),
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    marginBottom: '8px',
    fieldLabel: (theme: Theme) => ({
      fontSize: 14,
      letterSpacing: '.8px',
      lineHeight: '16px',
      color: 'rgba(41,47,65,.5)',
      textTransform: 'uppercase',
    }),
  },
  placement: {
    maxWidth: 680,
  },
  uploadFile: {
    height: 54,
    width: '100%',
  },
  pictures: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
  },
  fieldHelperText: {
    flexBasis: 'calc(50% - 8px)',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: '0.8px',
    color: 'rgba(41, 47, 65, 0.5)',
    marginBottom: '8px',
    textAlign: 'start',
  },
  fileUpload: {
    position: 'relative',
    alignSelf: 'flex-start',
  },
  uploadInput: {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    opacity: 0,
  },
  input: {
    '& fieldset': {
      borderRadius: '8px',
    },
    '& > .Mui-focused': {
      '& fieldset': {
      borderColor: '#E6E9ED !important'
      }
    },
    '&:hover fieldset': {
      borderColor: '#E6E9ED !important'
    }
  }
}
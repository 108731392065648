import { array, boolean, mixed, number, object, string } from "yup";
import { t } from "i18next";
import { CampaignObjective, CampaignObjectiveType } from "../types";
import { getUTF8Length } from "../../global";

function getCampaignObjectiveValidation() {
  return object().shape({
    bidding_strategy: object().shape({
      type: string().required(),
      app_promotion: object().shape({
        app_platform_type: string().required(t<string>('errorMessages.required')),
        bundle_id: string().required(t<string>('errorMessages.required')),
        mmp: string().required(t<string>('errorMessages.required')),
        tracking_url: string(),
      }).when(['type', 'goal'], {
        is: (type: string, goal: string) => type !== CampaignObjectiveType.CONSIDERATION || goal !== CampaignObjective.APP_INSTALLS,
        then: schema => schema.omit(['app_platform_type', 'bundle_id', 'mmp'])
      }),
      click_url: string().when(['type'], {
        is: (type: string) => type !== CampaignObjectiveType.OTHER,
        then: schema => schema.required(t<string>('errorMessages.required')),
      }),
      website_promotion: object().shape({
        conversion_url: string().required(t<string>('errorMessages.required')),
        custom_action_name: string().required(t<string>('errorMessages.required')),
      }).when('type', {
        is: (type: string) => type !== CampaignObjectiveType.CONVERSION,
        then: schema => schema.omit(['conversion_url', 'custom_action_name']),
      }).when(['type', 'goal', 'urlType'], {
        is: (type: string, goal: string, urlType: string) =>
          type !== CampaignObjectiveType.CONVERSION
          || goal !== CampaignObjective.WEB_STANDARD_CONVERSION
          || urlType !== 'conversion',
        then: schema => schema.omit(['conversion_url'])
      }).when(['type', 'goal'], {
        is: (type: string, goal: string) =>
          type !== CampaignObjectiveType.CONVERSION || goal !== CampaignObjective.WEBSITE_CUSTOM_EVENT,
        then: schema => schema.omit(['custom_action_name'])
      }),
    }),
  });
}

const createMaxValidation = (max: number, emptyAllowed = true) => {
  return string().max(max, t<string>('errorMessages.maxChar', { max }))
    .test('utf8EncodingLength', t<string>('errorMessages.maxChar', { max }), (value?: string) => {
      const utf8Length = value && value.match(/^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s]*$/gi) ? getUTF8Length(value || '') + (value.split(" ").length - 1) : getUTF8Length(value || '');
      return utf8Length <= max;
    }).min(emptyAllowed ? 0 : 1, t<string>('errorMessages.required'));
};

function getDynamicAdValidation(complete = false) {
  return object().shape({
    logo: complete ? array().of(object({ url: string().required() })).min(1) : array().of(object({ url: string() })),
    images: complete ? array().of(object({ url: string().required() })).min(1) : array().of(object({ url: string() })),
    callToActionColor: string(),
    callToAction: createMaxValidation(12, !complete),
    companyName: createMaxValidation(10, !complete),
    descriptions: array(object({ description: createMaxValidation(50, !complete) })),
    titles: array(object({ title: createMaxValidation(30, !complete) })),
    creative_name: complete ? string().required() : string(),
  });
}

function getVideoAdValidation(complete = false) {
  return object().shape({
    video: complete ? object().shape({
      url: string().required(),
    }) : mixed().nullable(),
    creative_name: complete ? string().required() : string(),
  });
}

function getNativeAdValidation(complete = false, isAppCampaign = false) {
  return object().shape({
    advertiser: isAppCampaign ? string() : createMaxValidation(25, !complete),
    cta: createMaxValidation(15, !complete),
    logo: complete ? object() : object().nullable(),
    // images: complete ? array().min(1) : array(),
    images: array(),
    body: createMaxValidation(90, !complete),
    headline: createMaxValidation(25, !complete),
    creative_name: complete ? string().required() : string(),
  }).when({
    is: !complete,
    then: schema => schema.nullable(),
  });
}

const utmRegex = /[?&](utm_source|utm_medium|utm_campaign)=/;

function getCampaignFormValidation(maxMargin: number, strict = false) {
  return object().shape({
    name: string().required(),
    country: string().required(),
    productURL: string().test(
      'no-utm-params',
      t<string>('errorMessages.noUtmParamsAllowed'),
      value => !utmRegex.test(value || '')
    ),
    budget: string().test('onlyPositive', t<string>('errorMessages.onlyPositive'), (value) => {
      const num = Number(value);
      if (isNaN(num)) {
        return false;
      }
      if (strict) {
        return num > 0;
      }
      return true;
    }),
    userCPX: number()
      .moreThan(-1,'The field value needs to be a number. Make sure to enter a numeric value.'),
    dynamicAdEnabled: boolean(),
    nativeAdEnabled: boolean(),
    videoAdEnabled: boolean(),
    dynamicAd: getDynamicAdValidation().when(['dynamicAdEnabled'], {
      is: (dynamicAdEnabled: boolean) => dynamicAdEnabled && strict,
      then: () => getDynamicAdValidation(true),
    }),
    visuals: strict ? object().when(['dynamicAdEnabled', 'nativeAdEnabled', 'videoAdEnabled', 'bidding_strategy.goal'], {
      is: (dyn: boolean, nat: boolean, video: boolean, goal: CampaignObjective) => {
        return !(dyn || nat || video || goal == CampaignObjective.AUDIO_ADVERTISING); // eslint-disable-line
      },
      then: () => object().shape({
        banners: array().min(1).of( object().shape({
          creative_name: string().required(),
        })),
      }),
    }) : object().shape({ visuals: array() }),

    bidding_strategy: object().shape({
      type: string().required(),
      app_promotion: object().shape({
        app_platform_type: string().required(t<string>('errorMessages.required')),
        bundle_id: string().required(t<string>('errorMessages.required')),
        mmp: string().required(t<string>('errorMessages.required')),
        tracking_url: string(),
      }).when(['type', 'goal'], {
        is: (type: string, goal: string) => {
          return type !== CampaignObjectiveType.CONSIDERATION || goal !== CampaignObjective.APP_INSTALLS},
        then: schema => schema.omit(['app_platform_type', 'bundle_id', 'mmp']).nullable()
      }),
      click_url: string().when(['type'], {
        is: (type: string) => type !== CampaignObjectiveType.OTHER,
        then: schema => schema.required(t<string>('errorMessages.required')),
      }),
      website_promotion: object().shape({
        conversion_url: string().required(t<string>('errorMessages.required')),
        custom_action_name: string().required(t<string>('errorMessages.required')),
        secondary_events: array(string().required(t<string>('errorMessages.required'))),
      }).when('type', {
        is: (type: string) => type !== CampaignObjectiveType.CONVERSION,
        then: schema => schema.omit(['conversion_url', 'custom_action_name']).nullable(),
      }).when(['type', 'goal', 'urlType'], {
        is: (type: string, goal: string, urlType: string) =>
          type !== CampaignObjectiveType.CONVERSION
          || goal !== CampaignObjective.WEB_STANDARD_CONVERSION
          || urlType !== 'conversion',
        then: schema => schema.omit(['conversion_url'])
      }).when(['type', 'goal'], {
        is: (type: string, goal: string) =>
          type !== CampaignObjectiveType.CONVERSION || goal !== CampaignObjective.WEBSITE_CUSTOM_EVENT,
        then: schema => schema.omit(['custom_action_name'])
      }).when(['goal'], {
        is: (goal: string) =>
          goal !== CampaignObjective.WEBSITE_CUSTOM_EVENT,
        then: schema => schema.omit(['secondary_events'])
      }),
    }),

    nativeAd: getNativeAdValidation().when(['nativeAdEnabled', 'bidding_strategy.goal'], ([nativeAdEnabled, goal], schema) => {
      if (nativeAdEnabled && strict) {
        return getNativeAdValidation(true, goal === CampaignObjective.APP_INSTALLS);
      }
      if (!nativeAdEnabled) {
        return object().shape({});
      }
      return schema;
    }),
    videoAd: getVideoAdValidation().when(['videoAdEnabled'], {
      is: (videoAdEnabled: boolean) => videoAdEnabled && strict,
      then: () => getVideoAdValidation(true),
    }),
    frequencyCap: object().shape({
      1: mixed().test(
        'positive',
        'Only positive Numbers allowed',
        (value: unknown) => {
          const transformed = Number(value);
          return transformed >= 0;
        }
      ),
      2: mixed().test(
        'positive',
        'Only positive Numbers allowed',
        (value: unknown) => {
          const transformed = Number(value);
          return transformed >= 0;
        }
      ),
      3: mixed().test(
        'positive',
        'Only positive Numbers allowed',
        (value: unknown) => {
          const transformed = Number(value);
          return transformed >= 0;
        }
      ),
    }),
    t: mixed().test(
      'positive',
      'Only positive Numbers allowed',
      (value: unknown) => {
        const transformed = Number(value);
        return transformed >= 0;
      }
    ),
    margin: mixed().test(
      'positive',
      `Only positive integer numbers allowed, less than ${maxMargin}`,
      (value: unknown) => {
        const transformed = Number(value);
        return maxMargin === null || value === null || (Number.isInteger(+value) && transformed <= maxMargin && transformed >= 0);
      }
    ).nullable(),
  });
}

const creativeNameRunTimeValidator = (name: string, callBack: (value: string) => void): string => {

  const reg = /^(\w|\.|-)+$/;
  const _name = name.replace(/ /g, '_');

  switch (true) {
    case !!_name && _name.length > 75: callBack('Names must be 75 characters or less');
      break;
    case !!_name && !reg.test(_name): callBack('Names can only contain letters, numbers, dashes, underscores no spaces');
      break;
    default: callBack('');
  }
  return _name;
}

export {
  getCampaignFormValidation,
  getCampaignObjectiveValidation,
  creativeNameRunTimeValidator,
}
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getCurrentSpaceBalanceRequest,
  getRefillPaymentHistoryRequest, getUserByIdRequest,
  invoicePayRequest,
} from "../../../../../../api";
import AdminHeader from "../../../../components/AdminHeader";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './style.scss';
import { useTranslation } from "react-i18next";
import {
  Box, Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { NotificationService } from "../../../../../global/services";
import { balanceParser, getPreviousWeek, IntDateRangePicker } from "../../../../../global";
import { StatusCode } from "../../../../../global/types";
import { invoicesHeadConfig } from "../AdminInvoiceList/config";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import StraightIcon from "@mui/icons-material/Straight";
import TableBodySkeleton from "../../../../../global/components/TableBodySkeleton";
import AdminInvoicesRow from "../AdminInvoiceList/AdminInvoicesRow";
import NoReports from "../../../../../report/components/NoReports";
import ManualPayModal from "../ManualPayModal";
import { fromDate, toDate } from "../../../../../report/services/report.service";

let initState = true;

interface IPayData {
  amount: string | number;
  referer: string;
  transaction_id: string;
  comment: string;
}

const UserDetails = ({className = ''}: {className? : string}) => {
  const history = useHistory();
  const {id} = useParams<{ id?: string; }>();
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice.userDetails' });

  const [user, setUser] = useState<any>(null);
  const [currentBalance, setCurrentBalance] = useState<number>(0)
  const [sorting, setSorting] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [date, setDate] = useState({from: getPreviousWeek(), to: new Date()});
  const [rowsPerPage] = useState<number>(10)
  const [page] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [manualPayOpen, setManualPayOpen] = useState<boolean>(false);


  const noDataToDisplay = paymentHistoryData?.length === 0 && !isLoading;

  const getPaymentHistory = (id: string) => {
    setIsLoading(true);
    const data = {
      user_id: id,
      status: null,
      from_date: fromDate(date.from),
      to_date: toDate(date.to),
      page: page + 1,
      size: rowsPerPage,
    }
    getRefillPaymentHistoryRequest(data).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        setPaymentHistoryData(res?.data?.data?.data);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      setIsLoading(false);
      initState = false;
    })
  }

  const getBalance = (id: string) => {
    // setIsLoading(true);
    getCurrentSpaceBalanceRequest(id).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK){
        setCurrentBalance(res?.data?.data);
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
      // setIsLoading(false);
    })
  }


  const onManualPay = () => {
    if(!id) return;
    setManualPayOpen(true);
  }

  const onPay = (data: IPayData ) => {
    if(!id) return;
    setLoading(true);
    invoicePayRequest(data, id).then((res) => {
      if(res?.data?.type?.code === StatusCode.OK) {
        NotificationService.handleSuccess('Payment done successfully');
        setLoading(false);
        setManualPayOpen(false);
        getPaymentHistory(id ? id : "");
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const onRowClick = (id: string) => {
    history.push(`/admin/invoice/details/${id}`)
  }

  useEffect(() => {
    if(!initState){
      getPaymentHistory(id ? id : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, rowsPerPage]);

  useEffect(() => {
    getPaymentHistory(id ? id : "");

    if(!!id){
      getBalance(id);

      getUserByIdRequest(id).then((res) => {
        if(res?.data?.type?.code === StatusCode.OK) {
          setUser(res?.data?.data);
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      })
    }

    return () => {
      initState = true;
      setUser(null)
      setPaymentHistoryData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdminHeader title={t("title")}>
        <Box className={'invoice-details-wrap'}>
          <Box className={'invoice-details-header'}>
            <Typography className={'navigate'} onClick={() => history.goBack()}><KeyboardBackspaceIcon fontSize='small' />{t('backToInvoiceList')}</Typography>
            <Box className={'invoice-details-header-actions'}>
              <Typography
                title={!!id ? '' : 'Select Account to Activate Manual Pay'}
                className={!!id ? 'btn active' : 'btn inactive' }
                onClick={() => onManualPay()}>
                {'Manual Pay'}
              </Typography>
            </Box>
          </Box>

          {!!user ? (<Box className={'invoice-details-body'}>
            <Box className={'general'}>
              <Box className={'block-header'}>
                <Typography className={'title-label'}>{'User Id:'}</Typography>
                <Typography>{user?.id}</Typography>
                <Typography className={'title-label'}>{'Name:'}</Typography>
                <Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>
                <Typography className={'title-label'}>{'Email:'}</Typography>
                <Typography>{`${user?.email}`}</Typography>
              </Box>
              <Box className={'block-body'}>
                <Box className={'left'}>
                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Balance:'}</Typography>
                      <Typography className={'item-text'}>{balanceParser(currentBalance, user?.currency)}</Typography>
                    </Box>
                  </Box>
                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Credit Limit:'}</Typography>
                      <Typography className={'item-text'}>{balanceParser(user?.credit_limit, user?.currency)}</Typography>
                    </Box>
                  </Box>
                  <Box className={'general-block-row'}>
                    <Box className={'general-block-item'}>
                      <Typography className={'item-label'}>{'Invoice Due Day:'}</Typography>
                      <Typography className={'item-text'}>{`${user?.invoice_due_day || 'n/a'}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={'transactions'}>
              <Box className={'block-body'}>
                <Paper sx={{width: '100%'}} className={className}>
                  <TableContainer className={'report-list'}>
                    <Box sx={{alignItems: 'center'}} className={'invoice-list-header top-radius'}>
                      <Box >
                        <Typography sx={{textTransform: 'uppercase', fontWeight: '600'}} >{`Invoices List`}</Typography>
                      </Box>

                      <Box>
                        <Typography className={'invoice-list-filter-date-label'}>{'show Payment History for'}</Typography>
                        <Box sx={{
                          float: 'right',
                          marginRight: '30px !important',
                          '& > div': {
                            height: '36px',
                            '& > div': {
                              border: 'none !important',
                              minWidth: '130px',
                              paddingLeft: 0,
                            }
                          }
                        }}>

                          <IntDateRangePicker
                            onChange={(date) => setDate(date)}
                            value={date}
                            name={'filterDate'}
                            includeDefaultPresets={false}
                            presets={[
                              { id: 1, label: 'Today', value: 0, selected: false },
                              { id: 2, label: 'Last 7 days', value: 7, selected: true },
                              { id: 3, label: 'Last 30 days', value: 30, selected: false },
                              { id: 4, label: 'Last 365 days', value: 365, selected: false },
                            ]}
                          />
                        </Box>
                      </Box>

                    </Box>
                    <Table>
                      <TableHead className={'report-list-head'}>
                        <TableRow>
                          {
                            invoicesHeadConfig.map(({key, orderField}:IReportHeadCell, index) => orderField ? (
                                <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: '16px'}}>
                                  <TableSortLabel
                                    IconComponent={sorting.field !== orderField ? SwapVertIcon : StraightIcon}
                                    active={sorting.field === orderField}
                                    direction={sorting.field === orderField && sorting.asc ? 'asc' : 'desc'}
                                    onClick={() => setSorting({field: orderField, asc: !sorting.asc})}
                                  >
                                    {t(`head.${key}`)}
                                  </TableSortLabel>

                                </TableCell>) : (
                                <TableCell key={key} size='small' className={'report-list-head-cell'} sx={{paddingLeft: index === 0 ? '16px !important' : '8px !important'}}>
                                  {t(`head.${key}`)}
                                </TableCell>
                              )
                            )
                          }
                          <TableCell size='small' className={'report-list-head-cell'}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {noDataToDisplay ? null : (
                          isLoading ? (
                            <TableBodySkeleton rowCount={rowsPerPage} cellCount={6} />
                          ) : (
                            <>
                              {paymentHistoryData && paymentHistoryData.map((row: any, i) => (
                                <AdminInvoicesRow
                                  showActions={true}
                                  config={invoicesHeadConfig}
                                  row={row}
                                  key={row.id}
                                  onRowClick={() => onRowClick(row.id)}
                                  onNameClick={() => undefined}
                                  onSendInvoice={() => undefined}/>
                              ))}
                            </>
                          )
                        )}
                      </TableBody>
                    </Table>
                    {noDataToDisplay && (
                      <NoReports />
                    )}
                  </TableContainer>

                  {manualPayOpen ? (
                    <ManualPayModal
                      t={t}
                      userId={id}
                      loading={loading}
                      title={t("manualPay")}
                      leftBtnText={t("cancel")}
                      rightBtnText={t("pay")}
                      onCancel={() => setManualPayOpen(false)}
                      onConfirm={(payData) => onPay(payData) }/>
                  ) : null}
                </Paper>
              </Box>
            </Box>
          </Box>) : null}
        </Box>
      </AdminHeader>
    </div>
  )
}

export default UserDetails;
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactNode, useEffect, useState } from "react";
import { AppCategory, CampaignFormDTO, CampaignObjective, CategoryApp } from "../../types";
import {
  Box,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { style } from "./style";
import "../AppsSitesSelectBox/style.scss";
import { InfoIcon, IntCheckbox, IntFieldController, IntSelect } from "../../../global";
import IntAutocomplete from "../../../global/components/IntForm/IntAutocomplete";
import AppsSitesSelectBox from "../AppsSitesSelectBox";
import { filtersConfig } from "../../utils/config";
import '../../styles/style.scss'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ENVIRONMENT } from "../../utils";
import AppsSitesCategoriesSelectBox from "../AppsSitesCategoriesSelectBox";
import DictService from "../../../dict/services/dict.service";

type Props = {
  footer: ReactNode;
}

enum dTHelper {
  'all' = 1,
  'ios'= 3,
  'android' = 4
}

function PlacementForm({ footer }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'campaign.placement',
  });
  const [appCategories, setAppCategories] = useState([]);
  const [gameCategories, setGameCategories] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState<Array<{ value: number; label: string }>>([]);
  const [languages, setLanguages] = useState<Array<{ value: string; label: string }>>([]);

  function getDeviceTypes() {
    DictService.getDictionary('device_types')
      .then((deviceTypes: any) => {
        setDeviceTypes(deviceTypes.map(({ key }) => ({
          value: key,
          label: t(`deviceTypes.${key}`),
        })));
      });
    DictService.getDictionary('languages')
      .then((lang: any) => {
        setLanguages(lang.map(({ value, title }) => ({
          value,
          label: title,
        })));
      });
  }

  const {
    control,
    setValue,
  } = useFormContext<CampaignFormDTO>();

  const [excludeAllGames, goal, platform] = useWatch({
    control,
    name: ['placement.excludeAllGames', 'bidding_strategy.goal', 'bidding_strategy.app_promotion.app_platform_type'],
  });


  function getAppCategories() {
    DictService.getAppGameCategories()
      .then((cat: any[]) => {
        setAppCategories((cat || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          };
        }));
      });
  }

  function getGameCategories() {
    DictService.getAppGameCategories(CategoryApp.GAME)
      .then((games: any[]) => {
        setGameCategories((games || []).map(function transferToOption(app: AppCategory) {
          return {
            label: app.category_name,
            value: app.category_id,
          }
        }))
      });
  }

  useEffect(() => {
    getAppCategories();
    getDeviceTypes();

    if(goal && goal === CampaignObjective.APP_INSTALLS) {
      // setValue('placement.environment', 'app');
      if(platform ) {
        setValue('placement.deviceType', dTHelper[platform]);
      }
    } else {
      // setValue('placement.environment', 'all');
      setValue('placement.deviceType', dTHelper['all']);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if(appCategories.length) {
      filtersConfig[0].dataList = appCategories;
    }
  }, [appCategories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!excludeAllGames && !gameCategories.length) {
      getGameCategories();
    }
  }, [excludeAllGames, gameCategories.length]);



  return (
    <Box sx={style.placement}>
      <Typography sx={style.title}>
        {t('title')}
      </Typography>
      <Box>
        <Divider />
        <Box sx={style.placementInputs}>
          <Box sx={style.appCategories}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('appCategories')}</Typography>
              <Tooltip title={t('appCategoriesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesCategoriesSelectBox data={appCategories} control={control} setValue={setValue}/>
          </Box>

          <Box sx={[style.field, style.devicesToDisplay, {position: 'relative'}]}>
            <Box sx={style.labelWithTooltip}>
              <Typography sx={style.labelWithTooltip.fieldLabel}>{t('SearchForSpecificAppsSites')}</Typography>
              <Tooltip title={t('SearchForSpecificAppsSitesTooltip')}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <AppsSitesSelectBox filtersConfig={filtersConfig} control={control} setValue={setValue} />
          </Box>
          <Divider sx={{width: '100%'}}/>
          {goal === CampaignObjective.APP_INSTALLS ? (
            <>
            <Box sx={{display: 'flex', width: '100%', gap: '16px'}}>
              <Box sx={[style.field, style.devicesToDisplay]}>
                <Typography sx={style.fieldLabel}>{t('environment')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.environment'
                  Component={IntSelect}
                  IconComponent={ExpandMoreIcon}
                  // disabled={isAppCampaign}
                  sx={[style.field, style.selectField]}
                  options={ENVIRONMENT}
                />
              </Box>
              <Box sx={[style.field, style.devicesToDisplay]}>
                <Typography sx={style.fieldLabel}>{t('devicesToDisplay')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.deviceType'
                  Component={IntSelect}
                  IconComponent={ExpandMoreIcon}
                  // disabled={isAppCampaign}
                  sx={[style.field, style.selectField]}
                  options={deviceTypes}
                />
              </Box>
              <Box sx={style.inputField}>
                <Typography sx={style.fieldLabel}>{t('minOsVersion')}</Typography>
                <IntFieldController control={control} name='placement.min_os_version' sx={style.input} />
              </Box>
            </Box>
            <Box sx={style.placementSelectFields}>
              <Box sx={[style.field, style.languageField]}>
                <Typography sx={style.fieldLabel}>{t('languages')}</Typography>
                <IntFieldController
                  className={'border-color-E6E9ED radius-8 int-select'}
                  control={control}
                  name='placement.languages'
                  Component={IntAutocomplete}
                  popupIcon={<ExpandMoreIcon />}
                  sx={[style.field, style.selectField]}
                  options={languages}
                  placeholder={t<string>('selectLanguage')}
                  multiple
                  extractValue
                />
              </Box>
            </Box>
          </>) : (
            <>
              <Box sx={style.placementSelectFields}>
                <Box sx={[style.field, style.devicesToDisplay]}>
                  <Typography sx={style.fieldLabel}>{t('environment')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.environment'
                    Component={IntSelect}
                    IconComponent={ExpandMoreIcon}
                    // disabled={isAppCampaign}
                    sx={[style.field, style.selectField]}
                    options={ENVIRONMENT}
                  />
                </Box>
                <Box sx={[style.field, style.languageField]}>
                  <Typography sx={style.fieldLabel}>{t('languages')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.languages'
                    Component={IntAutocomplete}
                    popupIcon={<ExpandMoreIcon />}
                    sx={[style.field, style.selectField]}
                    options={languages}
                    placeholder={t<string>('selectLanguage')}
                    multiple
                    extractValue
                  />
                </Box>
              </Box>
              <Box sx={style.placementSelectFields}>
                <Box sx={[style.field, style.devicesToDisplay]}>
                  <Typography sx={style.fieldLabel}>{t('devicesToDisplay')}</Typography>
                  <IntFieldController
                    className={'border-color-E6E9ED radius-8 int-select'}
                    control={control}
                    name='placement.deviceType'
                    Component={IntSelect}
                    IconComponent={ExpandMoreIcon}
                    // disabled={isAppCampaign}
                    sx={[style.field, style.selectField]}
                    options={deviceTypes}
                  />
                </Box>
              </Box></>
            )}

          <Divider sx={{width: '100%'}}/>

          <Box sx={style.excludeAllGames}>
            <IntFieldController
              control={control}
              name='placement.exclude_non_gambling_publishers'
              Component={IntCheckbox}
            />
            <Typography sx={style.excludeAllGamesLabel}>{t('excludeNonGamblingPublishers')}</Typography>
          </Box>
        </Box>
      </Box>
      {footer}
    </Box>
  )
}

PlacementForm.displayName = 'PlacementForm';

export default PlacementForm;
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog, DialogContent, DialogTitle, IconButton,
  Paper, Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { style } from './style';

import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import { useParams } from "react-router-dom";
import ChangeLogListRow from "../ChangeLogListRow";
import ReactJsonViewCompare from 'react-json-view-compare';
import NoCampaignLog from "../NoCampaignLog";
import moment from "moment";
import { NotificationService } from "../../../global/services";
import { useSelector } from "react-redux";
import { logCountSelector } from "../../../store/reducers/campaign";


type Props = {
  cellCount: number;
  rowCount?: number;
}

function TableBodySkeleton({ rowCount = 10, cellCount }: Props) {

  const rows = Array(rowCount).fill(undefined);
  // const cells = Array(cellCount).fill(undefined);

  return (
    <>
      {rows.map((row, idx) => (
        <TableRow className={'table-skeleton'} key={idx}>
            <TableCell sx={{padding: '4px 0 !important'}} className={`td-${idx}`} key={idx}>
              <Skeleton sx={{minWidth: '122px', height: '24px', margin: '0 16px !important'}} variant="rectangular" />
            </TableCell>
          <TableCell sx={{padding: '4px 0 !important'}} className={`td-${idx}`} key={idx}>
              <Skeleton sx={{minWidth: '518px', height: '24px',margin: '0 16px !important'}} variant="rectangular" />
            </TableCell>
          <TableCell sx={{padding: '4px 0 !important'}} className={`td-${idx}`} key={idx}>
              <Skeleton sx={{minWidth: '144px', height: '24px',margin: '0 16px !important'}} variant="rectangular" />
            </TableCell>
        </TableRow>
      ))}
    </>
  );
}

function ChangeLogList() {
  const changeLogCount = useSelector(logCountSelector);

  const { t } = useTranslation('translation', { keyPrefix: 'campaign.log' });

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const { id } = useParams<{ id?: string; }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedLog, setSelectedLog] = useState<any>(null);

  const createShowString = (properties: any[]) : string => {
    let showStr = "";
    properties.forEach((p) => {
      showStr += `${p.path}: ${p.value}, `
    })
    return `{ ${showStr} }`;
  }

  const getLog = useCallback((id: string, page, rowsPerPage) => {
    setIsLoading(true);
    const parsedData: any[] = [];
    CampaignService.getChangeLog(id as string, page + 1, rowsPerPage)
      .then((res) => {
        if (res?.data?.type?.code === StatusCode.OK) {
          res?.data?.data?.forEach((item: any) => {
              const obj = {
                authorName: item.author.email || item.author.name,
                difference: createShowString(item.state_difference),
                date: item.commit_date,
                previousState: item.previous_state,
                currentState: item.current_state,
              };
              parsedData.push(obj);
          })

          setIsLoading(false)
          setLogData(parsedData);
        } else {
          NotificationService.handleError(res?.data?.type?.message);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
    getLog(id, newPage, rowsPerPage)
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
    getLog(id, page, Number(event.target.value))
  }

 useEffect(() => {
   if(id){
     getLog(id, page, rowsPerPage)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [id]);


  function handleInfoModalOpen(log: any) {
    setSelectedLog(log);
    setInfoModalOpen(true);
  }

  function handleInfoModalClose() {
    setSelectedLog(null);
    setInfoModalOpen(false);
  }

  return (
    <>
      <Paper>
        <TableContainer sx={style.tableContainer}>
          {/*{!!logData.length ? (*/}
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" sx={style.headCell}>
                    {t("date")}
                  </TableCell>
                  <TableCell size="small" sx={style.headCell}>
                    {t("state")}
                  </TableCell>
                  <TableCell size="small" sx={style.headCell}>
                    {t("user")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {id ? (
                  isLoading ? (
                    <TableBodySkeleton rowCount={rowsPerPage} cellCount={3} />
                  ) : logData.map((row: any, index: number) => (
                    <ChangeLogListRow
                      data={row}
                      key={index}
                      onRowClick={() => {
                        handleInfoModalOpen(row);
                      }}
                    />
                  ))
                ) : null}
              </TableBody>
            </Table>
          {!logData.length && !isLoading  ? <NoCampaignLog /> : null}
        </TableContainer>
        {!!logData.length ? (<TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          count={changeLogCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{
            disabled: isLoading || changeLogCount < rowsPerPage || page + 1 === Math.ceil(changeLogCount / rowsPerPage),
          }}
          backIconButtonProps={{
            disabled: isLoading || changeLogCount < rowsPerPage || page === 0,
          }}
          component='div'
        />) : null}
      </Paper>

      {!!selectedLog && (
        <Dialog
          sx={style.dialog}
          // className='modal-dialog'
          open={infoModalOpen}
          onClose={handleInfoModalClose}
        >
          <DialogTitle sx={{padding: '16px 24px'}}>
            <Typography>
              {`Log Info (${moment(selectedLog.date).format('MMM DD, YYYY HH:mm')})`}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleInfoModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{display: 'flex'}}>
            <ReactJsonViewCompare  newData={selectedLog.currentState} oldData={selectedLog.previousState} />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

ChangeLogList.displayName = 'ChangeLogList';

export default ChangeLogList;
import { createSlice } from '@reduxjs/toolkit';
import { ICampaign, ICampaignAudienceSize, ICampaignCpmData, ICampaignList, ICampaignPolygons } from "../../../types";

export interface CampaignState {
  campaignId: string;
  logCount: Nullable<number>;
  cpmData: ICampaignCpmData;
  polygons: ICampaignPolygons[];
  audienceEstimatedSize: ICampaignAudienceSize;
  campaigns: ICampaignList;
  campaignStore: ICampaign;
  campaignIsLoading: boolean;
  campaignsIsLoading: boolean;
  cpmDataIsLoading: boolean;
  isSaveCampaignsLoaded: boolean;
  isPublishCampaignsLoaded: boolean;
  hasCampaign: null | boolean;
  audienceEstimatedSizeIsLoading: boolean;
  invoice: any;
}

export const campaignSlice = createSlice({

  name: "campaignStore",
  initialState: {
    logCount: null,
    campaignId: "",
    cpmData: {},
    cpmDataIsLoading: true,
    polygons: [],
    audienceEstimatedSize: {
      total: 1,
      estimate: 0,
      estimate_impressions: 1,
      total_impressions: 1,
    },
    campaigns: {
      data: [],
      current_page: 0,
      page_size: 10,
      total_size: 10,
    },
    campaignStore: {},
    isPublishCampaignsLoaded: false,
    isSaveCampaignsLoaded: false,
    campaignIsLoading: true,
    campaignsIsLoading: true,
    hasCampaign: null,
    audienceEstimatedSizeIsLoading: true,
    invoice: [],
  } as CampaignState,
  reducers: {
    setHasCampaign: (state, action): CampaignState => ({
      ...state,
      hasCampaign: action.payload,
    }),
    setLogCount: (state, action): CampaignState => ({
      ...state,
      logCount: action.payload,
    }),
  },
});

export const {setHasCampaign, setLogCount} = campaignSlice.actions;

export const hasCampaignSelector = (state: any) => state.campaignStore.hasCampaign;
export const invoiceDataSelector = (state: any) => state.campaignStore.invoice;
export const logCountSelector = (state: any) => state.campaignStore.logCount;


export default campaignSlice.reducer;

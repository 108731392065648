import { IDictionaryField } from "../types";

export const userRoles = {
  SYSTEM_ADMIN: 1,
  WL_OWNER: 10,
  ACCOUNTANT: 11,
  WL_MODERATOR: 12,
  USER_OWNER: 20,
};

export const roles = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  WL_OWNER: 'WL_OWNER',
  ACCOUNTANT: 'ACCOUNTANT',
  WHITELABEL_ACCOUNTANT: 'WHITELABEL_ACCOUNTANT',
  WL_MODERATOR: 'WL_MODERATOR',
  USER_OWNER: 'USER_OWNER',
};

export const USER_TYPES = {
  company: 1,
  individual: 2,
};

export const USER_STATUSES = {
  NEED_VERIFICATION: 1,
  APPROVED: 2,
  ACTIVE: 5,
};

export const USER_STATUSES_VIEW = {
  1: 'Need Verification',
  2: 'Pending',
  5: 'Active',
};

export const USER_TYPE_KEY = {
  LEGAL: 1,
  PHYSICAL: 2,
};

export const USER_STATUSES_SELECT_LIST: IDictionaryField[] = [
  {
    disabled: false,
    title: 'Need Verification',
    value: 1,
    children: {} as IDictionaryField
  },
  {
    disabled: false,
    title: 'Pending',
    value: 2,
    children: {} as IDictionaryField
  },{
    disabled: false,
    title: 'Active',
    value: 5,
    children: {} as IDictionaryField
  },
];
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {NEW_ROUTES } from "../../../../../index";
import AdminService from "../../../../services/admin.service";
import { getUserByIdRequest } from "../../../../../../api";
import { setHasCampaign } from "../../../../../store/reducers/campaign";
import { useDispatch } from "react-redux";
import { CoreTable } from "../../../../../global/components/Core-table";
import CampaignService from "../../../../../campaign/services/campaign.service";
import { CampaignListName } from "../CampaignListName";
import { TableNotFound } from "../../../../../global/components/TableNotFound";
import { StatusCode } from "../../../../../global/types";
import { NotificationService } from "../../../../../global/services";
import { setCurrentUser } from "../../../../../store/reducers/user";


const headCells = [
  {key: 'name', parser: (data: any) => data || "" }
];

const reqData: any = {
  date:{
    from_date: "2024-09-30T15:43:13.885Z",
    to_date: "2024-10-07T15:43:13.885Z",
    datePresetValue: {
      from_date: "2024-09-30T15:43:13.885Z",
      to_date: "2024-10-07T15:43:13.885Z"
    },
    key: "7_DAYS"
  },
  search: "",
  status: [],
  page: 1,
  dateCustomRange: false,
  dateLabel: "",
  from_date: "2024-09-30",
  to_date: "2024-10-07",
  status_filter: [1]
}


const AdminModerationCampaigns = () => {

  const [moderationCampaigns, setModerationCampaigns] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const renderNotfound = () => {
    return (
      <TableNotFound
        titleId="Admin.accountsNotFoundTitle"
        titleDefaultMessage="No Accounts found"
        descriptionId="Admin.accountsNotFoundDescription"
        descriptionDefaultMessage="Try adjusting filters or choosing different date range."
      />
    );
  };

  const setAdminNewSpace = (id: string, callBack ) => {
    getUserByIdRequest(id).then(async res => {
      if(res?.data?.type?.code === StatusCode.OK) {
        const user = res?.data?.data;
        dispatch(setCurrentUser(user));
        if (!user.id) {
          dispatch(setHasCampaign(false));
        } else {
          const countRes = await CampaignService.getSpaceCampaignCountRequest(user.id)
          dispatch(setHasCampaign(!!countRes?.data?.data));
        }
        sessionStorage.setItem('spaceID', user.id);
        callBack && callBack();
      } else {
        NotificationService.handleError(res?.data?.type?.message);
      }
    })
  }

  const getData = (reqData) => {
    setIsLoading(true);

    AdminService.getModerationCampaigns(reqData).then(({data}) => {

      const _moderationCampaigns = data.data.data.map(
        ({ name, id, favorite, status_key, campaign_start_at, space_id }) => {
          return {
            name: (
              <div
                onClick={() => {
                  setTimeout(() => {
                    setAdminNewSpace(space_id, () => {
                      history.replace({
                        pathname: NEW_ROUTES.campaign.edit.onEdit(id as string),
                      });
                    });
                  });
                }}
              >
                <CampaignListName
                  name={name}
                  favorite={favorite}
                  status_key={status_key}
                  campaign_start_at={campaign_start_at}
                />
              </div>
            ),
          };
        }
      );

      setModerationCampaigns({
        data: _moderationCampaigns,
        total_size: data.data.total_size,
        page_size: data.data.page_size,
        current_page: data.data.current_page,
        isLoading: false,
      });
      setIsLoading(false);
    })
  };

  const onPageChange = (newPage: number) => {
    getData({...reqData, page: newPage });
  }

  useEffect(() => {
    getData(reqData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CoreTable
        data={moderationCampaigns}
        translationPrefix={'admin.moderation.campaignList'}
        loading={isLoading}
        headCells={headCells}
        hasPagination={true}
        noData={renderNotfound()}
        onPageChange={(page) => onPageChange(page)}
        onRowClick={(row) => undefined}
      />
    </div>
  );

}

export default injectIntl(AdminModerationCampaigns);

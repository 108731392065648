import { Checkbox, CheckboxProps } from "@mui/material";
import { ChangeEvent } from "react";

type Props = Omit<CheckboxProps, 'onChange'> & {
  errorMessage?: string;
  value: boolean;
  onChange: Function;
};

function IntCheckbox({
  errorMessage,
  onChange,
  value,
  ...props
}: Props) {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return (
    <Checkbox checked={!!value} onChange={handleChange} {...props}/>
  )
}

IntCheckbox.displayName = 'IntCheckbox';

export default IntCheckbox;
import moment from "moment";
import { numberToStringWithCommas } from "../../../../../global";
import { CURRENCYICONS } from "../../../../../store";

export const invoicesHeadConfig: IReportHeadCell[] = [
  {
    key: 'id',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'status',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'to_be_paid',
    parser: (field) => numberToStringWithCommas(Math.ceil(field * 100) / 100 || 0, 2),
  },
  {
    key: 'from_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
  {
    key: 'to_date',
    parser: (field) => moment(field).format('DD/MM/yyyy'),
  },
];

export const usersHeadConfig: IReportHeadCell[] = [
  {
    key: 'user_name',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'user_id',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'email',
    parser: (field) => field || 'n/a',
  },
  {
    key: 'to_be_paid',
    parser: (field) => numberToStringWithCommas(Math.ceil(field * 100) / 100 || 0, 2),
  },
];

export const getCampaignSpentHeadCells = (currency: string) => [
  {key: 'campaign_name', parser: (data: string) => data || "n/a" },
  {key: 'campaign_id', parser: (data: string) => data || "" },
  {key: 'impression_count', parser: (data: string) => data || "-" },
  {key: 'amount', parser: (data: number) => `${CURRENCYICONS[currency]} ${numberToStringWithCommas(data, 2)}`}
];

export const getTransactionHeadCells = (currency: string) => [
  {key: 'id', parser: (data: string) => data || "n/a" },
  {key: 'amount', parser: (data: number) => `${CURRENCYICONS[currency]} ${numberToStringWithCommas(data, 2)}`},
  {key: 'status', parser: (data: string) => data || "" },
  {key: 'transaction_date', parser: (data: string) => moment(data).format('DD/MM/yyyy HH:mm') || "" },
  {key: 'transaction_id', parser: (data: string) => data || 'n/a' },
  {key: 'comment', parser: (data: string) => data ? (
    <span style={{
      maxWidth: '100px',
      overflow: 'hidden',
      display: 'block',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }} title={data}>{data}</span>) : 'n/a' },
];

/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/13/2023
 * All Rights Reserved.
 */
import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { style } from './style';
import { secondsToTimeFormat } from "../../utils";
import { CrossIcon, PlayerPauseIcon, PlayerPlayIcon } from "../Icons";

type Props = {
  file: {
    name: string;
    url: string;
    size: number;
    fileId: string;
  };
  onRemove: (id: string) => void;
}

function AudioPlayer({ file, onRemove}: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(new Audio(file.url));

  useEffect(() => {
    const audio = audioRef.current;

    function loadDuration() {
      setDuration(audio.duration);
    }

    function loadTimeUpdate() {
      setCurrentTime(audio.currentTime);
    }

    audio?.addEventListener('loadedmetadata', loadDuration);

    audio?.addEventListener('timeupdate', loadTimeUpdate);

    return () => {
      audio?.removeEventListener('loadedmetadata', loadDuration);
      audio?.removeEventListener('timeupdate', loadTimeUpdate);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  return <Box sx={style.player}>
    <Box>
      <Typography sx={style.filename}>{file.name}</Typography>
      <Typography sx={style.filesize}>{file.size.toFixed(1)}Mb</Typography>
    </Box>
    <Box sx={style.playerActions}>
      <Typography sx={style.duration}>
        {(isPlaying || currentTime !== 0) && secondsToTimeFormat(currentTime)}
      </Typography>
      <Box sx={style.playerState}>
        <Box sx={style.loadedPercentage(currentTime / duration * 100)} />
      </Box>
      <Typography sx={style.duration}>{secondsToTimeFormat(duration)}</Typography>
      {isPlaying ? (
        <PlayerPauseIcon
          viewBox='0 0 36 36'
          sx={style.playerIcon}
          onClick={togglePlayPause}
        />
      ) : (
        <PlayerPlayIcon
          viewBox='0 0 36 36'
          sx={style.playerIcon}
          onClick={togglePlayPause}
        />
      )}
      <CrossIcon style={style.crossIcon} onClick={() => onRemove(file.fileId)} />
    </Box>
  </Box>
}

AudioPlayer.displayName = 'AudioPlayer';

export default AudioPlayer;
import "./style.scss"
import { useTranslation } from "react-i18next";
import {
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TablePagination,
  TableRow, TableSortLabel,
} from "@mui/material";
import { style } from './style';
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import StraightIcon from '@mui/icons-material/Straight';
import AdminCampaignListRow from "../AdminCampaignListRow";
import AdminCampaignListFilter from "../AdminCampaignListFilter";
import { CAMPAIGN_LIST_DEFAULT_FILTER } from "../../../../../campaign/utils";
import { CampaignsList } from "../../../../../campaign/types";
import { setLogout } from "../../../../../store/reducers/authentication";
import { CampaignService } from "../../../../../campaign/services";
import { StatusCode } from "../../../../../global/types";
import { NotificationService } from "../../../../../global/services";
import { NEW_ROUTES, useQuery } from "../../../../../global";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../../../../../_constants";
import { routerPaths } from "../../../../../../config/routes/paths";
import NoCampaigns from "../../../../../campaign/components/NoCampaigns";
import { CURRENCYICONS } from "../../../../../store";
import { currentUserSelector } from "../../../../../store/reducers/user";

const baseData = (function(count: number){
  const fakeData = [];
  for(let i = 1; i <= count; i++) {
    fakeData.push({
      id: i.toString(),
      status_key: 0,
      name: "",
      campaign_start_at: "",
      campaign_type_key: 1,
      daily_budget_spent: 0,
      impressions: 0,
      clicks: 0,
      budget_spent: 0,
      campaignType: "'WEB STANDARD CONVERSION'",
      budget_type_key: 1,
      budget: 0,
      report_data: {cpa: 0, cvr: 0}
    })
  }
  return fakeData;
})(10);

let noDataToDisplay = false;

const initialState = {
  field: '',
  asc: false,
}

function AdminCampaignList() {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.list' });

  const { control, getValues,setValue } = useForm({
    defaultValues: {...CAMPAIGN_LIST_DEFAULT_FILTER, status_filter: [3]}
  });

  const [creationModalOpen, setCreationModalOpen] = useState(false);

  // const date = useWatch({
  //   control,
  //   name: 'date',
  // });

  const [campaigns, setCampaigns] = useState<CampaignsList>({
    data: baseData,
    totalCount: 0,
    isLoading: true,
  });
  const currentUser = useSelector(currentUserSelector);

  // const [, setReportDict] = useState<{ [key: string]: CampaignReport }>({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [noDataToDisplay, setNoDataToDisplay] = useState<boolean>(false);
  const [sorting, setSorting] = useState(initialState);
  const searchParams = useQuery();


  const history = useHistory();
  const dispatch = useDispatch();
  // const campaignIds = campaigns.data.map(campaign => campaign.id);


  const page = Number(searchParams.get('page')) || 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCampaigns = useCallback(debounce(function getCampaigns(data) {
    if(data.fromFiler) {
      setSorting({field: '', asc: false});
      delete data.fromFiler;
    }
    setCampaigns(data => ({
      ...data,
      isLoading: true,
      // data: [],
    }));
    CampaignService.getCampaignsAll({ ...data, page: page + 1, pageSize: rowsPerPage })
      .then((response) => {
        const camps = response?.data;
        if (camps?.type?.code === StatusCode.OK) {
          noDataToDisplay = camps?.data?.data?.length === 0;
          const _data = camps?.data?.data?.length !== 0 ? camps?.data?.data : baseData;
          setCampaigns({
            data: _data,
            totalCount: camps?.data?.total_size,
            isLoading: false,
          });
        } else {
          NotificationService.handleError(camps?.type?.message);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 200), [page, rowsPerPage]);

  async function archiveCampaign(campaignId: string) {
    const response = await CampaignService.archiveCampaign(campaignId);
    // eslint-disable-next-line
    if (response?.data?.type?.code == StatusCode.OK) {
      NotificationService.handleSuccess(t('archived'));
      const fields = getValues();
      getCampaigns({
        from_date: fields.date?.from,
        to_date: fields.date?.to,
        name: fields.name,
        status_filter: fields.status_filter,
        space_id: currentUser.id
      });
    } else {
      NotificationService.handleError(response?.data?.type?.message);
    }
  }

  function handleSorting(orderField: string, orderAsc: boolean ): void {
    const fields = getValues();
    getCampaigns({
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter,
      order_field: orderField,
      order_asc: orderAsc,
      space_id: currentUser.id
    });
  }

  function updateCampaignList(): void {
    const fields = getValues();
    getCampaigns({
      from_date: fields.date?.from,
      to_date: fields.date?.to,
      name: fields.name,
      status_filter: fields.status_filter,
      order_field: sorting.field,
      order_asc: sorting.asc,
      space_id: currentUser.id
    });
  }

  function handleChangePage(event: unknown, newPage: number) {
    history.replace(`/admin/campaigns?page=${newPage}`);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(Number(event.target.value));
  }

  function replicateCampaign(id: string) {
    history.replace(NEW_ROUTES.campaign.new.onReplicate(id));
  }


  function handleCreationModalOpen() {
    setCreationModalOpen(true);
  }

  function handleCreationModalClose() {
    setCreationModalOpen(false);
  }
  
  useEffect(() => {
    const page = Number(searchParams.get('page')) || 0;
    sessionStorage.setItem('referrer', `/admin/campaigns?page=${page}`);
  }, [searchParams]);

  useEffect(() => {
    handleSorting(sorting.field, sorting.asc);
    // eslint-disable-next-line
  }, [sorting.field, sorting.asc]);

  useEffect(() => {
    if(!localStorage.getItem(AUTH_TOKEN)){
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      sessionStorage.removeItem('currentAccountCurrency');
      dispatch(setLogout());
      history.push(routerPaths.Login);
    }
    return () => {
      setCampaigns({
        data: baseData,
        totalCount: 0,
        isLoading: true,
      })
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdminCampaignListFilter
        setValue={setValue}
        getCampaigns={getCampaigns}
        userId={currentUser.id}
        control={control}
        modalOpen={creationModalOpen}
        handleModalOpen={handleCreationModalOpen}
        handleModalClose={handleCreationModalClose}
      />
      <Paper>
        <TableContainer className={'campaign-list'} sx={style.tableContainer}>
          <Table>
            <TableHead sx={{backgroundColor: '#F4F6FA'}}>
              <TableRow>
                <TableCell size='small' sx={{...style.headCell, minWidth:'430px', paddingLeft: '16px',textAlign: 'start !important'}}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'name' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'name'}
                    direction={sorting.field === 'name' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => {
                      setSorting({field: 'name', asc: !sorting.asc})
                      }
                    }
                  >
                  {t('head.name')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={{...style.headCell}}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'impressions' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'impressions'}
                    direction={sorting.field === 'impressions' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'impressions', asc: !sorting.asc})}
                  >
                    {t('head.impressions')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'clicks' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'clicks'}
                    direction={sorting.field === 'clicks' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'clicks', asc: !sorting.asc})}
                  >
                    {t('head.clicks')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'conversions' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'conversions'}
                    direction={sorting.field === 'conversions' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'conversions', asc: !sorting.asc})}
                  >
                    {t('head.conversions')}
                  </TableSortLabel>
                </TableCell>

                <TableCell size='small' sx={style.headCell}>
                  <TableSortLabel
                    IconComponent={sorting.field !== 'spent' ? SwapVertIcon : StraightIcon}
                    active={sorting.field === 'spent'}
                    direction={sorting.field === 'spent' && sorting.asc ? 'asc' : 'desc'}
                    onClick={() => setSorting({field: 'spent', asc: !sorting.asc})}
                  >
                    {t('head.spend')}
                  </TableSortLabel>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody sx={{position: 'relative'}}>

              {campaigns.data.map(row => (
                <AdminCampaignListRow
                  campaign={row}
                  updateCampaignList={updateCampaignList}
                  key={row.id}
                  noDataToDisplay={noDataToDisplay && !campaigns.isLoading}
                  isLoading={campaigns.isLoading}
                  report={row.report_data}
                  currency={CURRENCYICONS[currentUser?.currency]}
                  archiveCampaign={archiveCampaign}
                  replicateCampaign={replicateCampaign}
                />
              ))}

              {noDataToDisplay ? (
                <NoCampaigns sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '500px',
                backgroundColor:'#FFFFFF',
                zIndex: 5}} />
              ) : null}

            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25]}
          count={campaigns.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{
            disabled: campaigns.isLoading || page + 1 === Math.ceil(campaigns.totalCount / rowsPerPage),
          }}
          backIconButtonProps={{
            disabled: campaigns.isLoading || page === 0,
          }}
          component='div'
        />
      </Paper>
    </>
  );
}


export default AdminCampaignList;